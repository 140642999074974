import React, { useContext, useEffect, useMemo, useState } from 'react';
import { List, ListItem, ListItemText, Collapse, ListSubheader, styled, ListItemButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import colors from 'constants/colors';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';
import { LayoutContext } from './MainLayout';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import AdbIcon from '@mui/icons-material/Adb';
import AuthCode from 'constants/auth-codes.constants';


// link 가 false 이면 link 가 안됨
const dailyMenus = [
    { 
        route: '/statistics',
        link: true,
        name: '통계',
    },
    { 
        route: '/ticket',
        link: true,
        name: '문의',
        children: [],
    },
    {
        route: '/statistics/last-access',
        link: true,
        name: '최근접속통계',
    },
    {
        route: '/statistics/referrer',
        link: true,
        name: '접속통계',
    },
    {
        route: '/statistics/host-referrer',
        link: true,
        name: '호스트 접속통계',
    },
    { 
        route: '/statistics/log',
        link: true,
        name: '로그',
    },
];

const dataMenus = [
    { 
        route: '/data/user',
        link: true,
        name: '사용자',
        children: [],
    },
    {
        route: '/data/space',
        link: true,
        name: '스페이스',
        children: [],
    },
    {
        route: '/data/trader',
        link: true,
        name: '거래처',
        children: [],
    },
    {
        route: '/data/place',
        link: true,
        name: '플레이스',
        children: [],
    },
    {
        route: '/data/item',
        link: true,
        name: '아이템',
        children: [],
    },
    {
        route: '/data/action',
        link: true,
        name: '액션',
        children: [],
    },
    {
        route: '/data/alarm',
        link: true,
        name: '알림',
        children: [],
    },
];

const settingMenus = [
    { 
        route: '/setting',
        link: true,
        name: '설정',
    },
]

const developmentMenus = [
    {
        route: '/development/view',
        link: false,
        name: 'View',
        icon: (<LandscapeOutlinedIcon />),
        children: [
            {
                route: '/development/view/typography',
                name: 'Typography',
                link: true,
            },
            {
                route: '/development/view/color',
                link: true,
                name: 'Color',
            },
            {
                route: '/development/view/button',
                link: true,
                name: 'Button',
            },
            {
                route: '/development/view/table',
                link: true,
                name: 'Table',
            },
            {
                route: '/development/view/enhanced-table',
                link: true,
                name: 'EnhancedTable',
            },
            {
                route: '/development/view/input',
                link: true,
                name: 'Input',
            },
            {
                route: '/development/view/key-value',
                link: true,
                name: 'Key Value',
            },
            {
                route: '/development/view/modal',
                link: true,
                name: 'Modal',
            },
            {
                route: '/development/view/guide',
                link: true,
                name: 'Guide',
            },
            {
                route: '/development/view/transferList',
                link: true,
                name: 'TransferList',
            },
        ]
    },
    {
        route: '/space/development/api',
        link: false,
        name: 'API',
        icon: (<AdbIcon />),
        children: [
            {
                route: '/space/development/api/foo',
                link: true,
                name: 'FOO',
            },
            {
                route: '/space/development/api/infinite-scroll',
                link: true,
                name: 'InfiniteScroll',
            },
        ]
    },
];

const ListHeader = styled(ListSubheader)(({ theme }) => ({
    color: 'white',
    backgroundColor: colors.gray600,
    lineHeight: '32px',
    fontWeight: 'bold',
}));

function SidebarMenus() {
	return (
		<div>
            <List
                component="nav"
                subheader={<ListHeader component="div">Daily</ListHeader>}
            >
                {dailyMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListHeader component="div">Data</ListHeader>}
            >
                {dataMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListHeader component="div">Setting</ListHeader>}
            >
                {settingMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListHeader component="div" className='MenuHeader'>Development</ListHeader>}
                // className={classes.root}
            >
                {developmentMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>
        </div>
	);
}


const HirachicalMenuItem = ({ menu, isChild }) => {
    const navigate = useNavigate();

    const haveChildren = useMemo(() => {
        return menu.children && menu.children.length > 0
    }, []);

    const location = useLocation();
    const [ menuOpen, setMenuOpen ] = useState(haveChildren && location.pathname.startsWith(menu.route));

    const { setPageTitle } = useContext(LayoutContext);
    const matchExact = !!useMatch(menu.route);
    useEffect(() => {
        if (matchExact) setPageTitle(menu.name);
    }, [ matchExact ]);

    const onMenuItemClick = () => {
        if (menu.link) navigate(menu.route);
        if (haveChildren) setMenuOpen(val => !val);
    }

    return (
        <>
            <ListItemButton
                onClick={onMenuItemClick}
                style={{ color: matchExact ? colors.secondary : colors.textPrimary }}
                // className={clsx({ [classes.nested]: isChild, [classes.menuitem]: !isChild, [classes.active]: matchExact, [classes.inactive]: !matchExact })}
            >
                {menu.icon}
                <ListItemText primary={menu.name} style={{ marginLeft: menu.icon ? '8px' : '32px' }} />
                
                {haveChildren && <>
                    {menuOpen ? <ExpandLess /> : <ExpandMore />}
                </>}
            </ListItemButton>

            {haveChildren && <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menu.children.map((child, index) => <HirachicalMenuItem key={index} menu={child} isChild={true} />)}
                </List>
            </Collapse>}
        </>
    );
}

export default SidebarMenus;