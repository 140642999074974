import React, { useState, useEffect } from 'react';
import { Button, Paper, Typography, Link as MuiLink } from '@mui/material';
import API from 'api';
import Guide from 'components/Guide';
import Loading from 'components/Loading';
import { Link } from 'react-router-dom';



const GoogleOAuthRequest = () => {
    const [ info, setInfo ] = useState();
    useEffect(() => {
        API.get('/admin/latest-google-oauth')
        .then(data => {
            setInfo(data);
        });
    }, []);

    const onUpdateAccessToken = () => {
        API.get('/admin/generate-google-oauth-url')
        .then(data => {
            const url = data.url;
            window.location.href = url;
        })
    }

    // if (!info) return (<Loading />);

	return (
        <Paper className="pagePadding" sx={{ p: 2 }}>
            <Typography variant="h5">Google OAuth Token</Typography>

            {info ? <div style={{ marginBottom: '16px' }}>
                <div>access_token: {info.meta.access_token}</div>
                <div>refresh_token: {info.meta.refresh_token}</div>
                <div>업데이트 된 시각: {info.createdAt}</div>
            </div> : <div>구글 OAuth2 정보가 없습니다.</div>}
            
            <Guide>구글 refresh_token 의 유효기간은 일주일로 추정하고 있습니다. 최근 업데이트 된 후 일주일이 되기 전에 다시 업데이트를 해주세요. 아이디: (cosmosrainbusan@gmail.com)</Guide>

            <a target="_blank" href={`${process.env.REACT_APP_HOST}/hook/google-oauth`}>Update Oauth2 Access Token</a>
        </Paper>
	);
}

export default GoogleOAuthRequest;