import React, { useContext } from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, TablePagination } from '@mui/material';
import { datetime_pipe, number_format_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { AppContext } from 'contexts/app.context';
import { Link as RLink } from 'react-router-dom';
import TablePaginationActions from 'components/TablePaginationActions';


export default function SpaceTable({ rows, totalCount, rowsPerPage, page, setPage, mode, onSelect }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const { showSpaceDetail } = useContext(AppContext);

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table style={{ minWidth: '650px' }} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>일련번호</StyledTableCell>
                            <StyledTableCell>이름</StyledTableCell>
                            {mode !== 'select' && <StyledTableCell>회원수</StyledTableCell>}
                            {mode !== 'select' && <StyledTableCell>아이템수</StyledTableCell>}
                            {mode !== 'select' && <StyledTableCell>액션수</StyledTableCell>}
                            {mode !== 'select' && <StyledTableCell>생성일시</StyledTableCell>}
                            {mode !== 'select' && <StyledTableCell>데이터사용량</StyledTableCell>}
                            {mode === 'select' && <StyledTableCell>선택</StyledTableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th"><Link href="#" onClick={() => { showSpaceDetail(row.id); }}>{row.id}</Link></StyledTableCell>
                                <StyledTableCell>{row.name}</StyledTableCell>
                                {mode !== 'select' && <StyledTableCell>{number_format_pipe(row.numberOfUsers)}</StyledTableCell>}
                                {mode !== 'select' && <StyledTableCell>{number_format_pipe(row.numberOfItems)}</StyledTableCell>}
                                {mode !== 'select' && <StyledTableCell>{number_format_pipe(row.numberOfActions)}</StyledTableCell>}
                                {mode !== 'select' && <StyledTableCell>{datetime_pipe(row.createdAt)}</StyledTableCell>}
                                {mode !== 'select' && <StyledTableCell><Link component={RLink} to={`./data-usage/${row.id}`}>보기</Link></StyledTableCell>}
                                {mode === 'select' && <StyledTableCell><Button variant="outlined" size="small" onClick={() => { onSelect(row); }}>선택</Button></StyledTableCell>}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    );
}