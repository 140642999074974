import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import Chart from 'chart.js/auto';



const DataUsageChart = ({ labels, dataset, div, options }) => {

   
    
    useEffect(() => {
        const ctx = document.getElementById(div);

        const data = {
            labels,
            datasets: [
                {
                    label: "개수",
                    data: dataset
                }
            ],
        };
        
        const config = {
            type: 'line',
            data: data,
            options,
        };

        const chart = new Chart(ctx, config);

        return (() => {
            chart.destroy();
        })
    }, [ labels, dataset ]);

	return (
		<Box>
            <canvas id={div}></canvas>
		</Box>
	);
}

export default DataUsageChart;