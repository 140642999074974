import React, { useMemo, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import Title from 'components/Title';
import CircularButton from 'components/CircularButton';
import { useSearchParams } from 'react-router-dom';
import DataListWrapper from 'components/DataListWrapper';
import SpaceSelectionModal from 'views/space/SpaceSelectionModal';
import colors from 'constants/colors';
import ActionTable from './ActionTable';
import useSearchActionobj from 'api/action/useSearchActionobj';
import PlaceSelectionModal from 'views/place/PlaceSelectionModal';
import ItemSelectionModal from 'views/item/ItemSelectionModal';


const ActionListPage = () => {
	const [ searchParams, setSearchParams ] = useSearchParams();

	const [ page, setPage ] = useState(0);	
	const [ keyword, setKeyword ] = useState('');
    const [ spaceId, setSpaceId ] = useState();
	const [ placeId, setPlaceId ] = useState();
	const [ itemId, setItemId ] = useState();

	const params = useMemo(() => {
		const page = searchParams.get('page') || 0;
		const keyword = searchParams.get('keyword') || '';
        const spaceId = searchParams.get('spaceId');
		const placeId = searchParams.get('placeId');
		const itemId = searchParams.get('itemId');

		setPage(+page);
		setKeyword(keyword);
        setSpaceId(spaceId);
		setPlaceId(placeId);
		setItemId(itemId);

		return ({
			page,
			keyword,
            spaceId,
			placeId,
			itemId,
		})
	}, [ searchParams ]);

	const { loading, error, rows, count, rowsPerPage, fetched } = useSearchActionobj(page, params);
    // const { loading, error, rows, count, rowsPerPage, fetched } = useSearchActionobj(page, space?.id, params, trigger);

    const handleSearch = (event) => {
		event.preventDefault();

		setSearchParams({
			page: 0,
			keyword,
            spaceId: spaceId || '',
			placeId: placeId || '',
			itemId: itemId || '',
		});
    }

	const [ spaceModalOpen, setSpaceModalOpen ] = useState(false);
	const [ placeModalOpen, setPlaceModalOpen ] = useState(false);
	const [ itemModalOpen, setItemModalOpen ] = useState(false);

	return (
		<Box>
			<Title>액션</Title>

			<form onSubmit={handleSearch}>
				<Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
					<TextField
						size='small'
						sx={{ minWidth: '300px' }}
						label={'키워드: Item.name or Item.code'}
						variant="outlined"
						onChange={(event) => { setKeyword(event.target.value); }}
						value={keyword}
					/>

                    {!spaceId ? <Button sx={{ ml: 1, color: colors.textSecondary }} variant='outlined' onClick={() => { setSpaceModalOpen(true); }}>스페이스 선택</Button>
                    : <Button sx={{ ml: 1, color: colors.secondary }} variant='outlined' onClick={() => { setSpaceId(null); }}>선택 해제</Button>}
					
					{!placeId ? <Button sx={{ ml: 1, color: colors.textSecondary }} variant='outlined' onClick={() => { setPlaceModalOpen(true); }}>하우스 선택</Button>
                    : <Button sx={{ ml: 1, color: colors.secondary }} variant='outlined' onClick={() => { setPlaceId(null); }}>선택 해제</Button>}

					{!itemId ? <Button sx={{ ml: 1, color: colors.textSecondary }} variant='outlined' onClick={() => { setItemModalOpen(true); }}>아이템 선택</Button>
                    : <Button sx={{ ml: 1, color: colors.secondary }} variant='outlined' onClick={() => { setItemId(null); }}>선택 해제</Button>}
					
					<CircularButton style={{ marginLeft: '8px' }} variant='contained' type="submit">검색</CircularButton>
				</Box>
			</form>

			<DataListWrapper
				loading={loading}
				fetched={fetched}
				length={rows?.length}
				nodata={'액션이 없습니다.'}
			>
				<ActionTable
					rows={rows}
					totalCount={count}
					rowsPerPage={rowsPerPage}
					page={page}
					setPage={setPage}
				/>
			</DataListWrapper>

            <SpaceSelectionModal open={spaceModalOpen} setOpen={setSpaceModalOpen} handleSelectSpace={space => setSpaceId(space.id)} />
			<PlaceSelectionModal open={placeModalOpen} setOpen={setPlaceModalOpen} handleSelect={place => setPlaceId(place.id)} spaceId={spaceId} />
			<ItemSelectionModal open={itemModalOpen} setOpen={setItemModalOpen} handleSelect={item => setItemId(item.id)} spaceId={spaceId} />
		</Box>
	);
}

export default ActionListPage;