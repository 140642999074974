import React, { useState, useContext } from 'react';
import { Box, TextField, MenuItem } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import Button from 'components/Button';
import useSearchAppLog from 'api/admin/useSearchAppLog';
import Title from 'components/Title';
import CircularButton from 'components/CircularButton';
import Loading from 'components/Loading';
import LogTable from './LogTable';


const LogPage = () => {
    const { showSnackbar, showAlert } = useContext(AppContext);

    const [ page, setPage ] = useState(0);
	const [ params, setParams ] = useState({});
	
    const [ division, setDivision ] = useState('');
	const [ code, setCode ] = useState('');
    const [ userId, setUserId ] = useState('');
    const [ trigger, setTrigger ] = useState();

	
	const { loading, error, rows, mayMore, totalCount, rowsPerPage } = useSearchAppLog(page, params, trigger);

    const handleSearchClick = () => {
        setParams({
            division,
            code,
            userId,
        });
        setPage(0);
    }

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

    const [ checked, setChecked ] = useState([]);
    const handleRemoveSelected = () => {
        if (checked.length === 0) return showAlert('하나 이상 선택해주세요.');
        
        const ids = checked.join(',');
		API.delete(`/admin/app-logs?ids=${ids}`)
		.then(() => {
			showSnackbar('삭제했습니다.');
            handleRefresh();
		});
    }


	return (
		<Box>
            <Title>로그</Title>

            <Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
                <TextField
                    select
                    size='small'
                    sx={{ width: '100px' }}
                    label={'구분'}
                    variant="outlined"
                    onChange={(event) => { setDivision((event.target.value)); }}
                    value={division || ''}
                >
                    <MenuItem value={''}>모두</MenuItem>
                    <MenuItem value={'error'}>에러</MenuItem>
                    <MenuItem value={'info'}>정보</MenuItem>
                </TextField>

                <TextField
                    size='small'
                    sx={{ ml: 2, width: '100px' }}
                    label={'코드'}
                    variant="outlined"
                    onChange={(event) => { setCode(event.target.value); }}
                    value={code || ''}
                />

                <TextField
                    size='small'
                    sx={{ ml: 2, width: '100px' }}
                    label={'userId'}
                    variant="outlined"
                    onChange={(event) => { setUserId(event.target.value); }}
                    value={userId || ''}
                />
                
                <CircularButton style={{ marginLeft: '8px' }} variant='contained' onClick={handleSearchClick}>검색</CircularButton>
				<Button variant="contained" style={{ marginLeft: '8px' }} color="error" onClick={handleRemoveSelected}>선택 삭제</Button>
            </Box>

            {!loading ? <>
                <LogTable
                    loading={loading}
                    rows={rows} 
                    totalCount={totalCount} 
                    rowsPerPage={rowsPerPage} 
                    page={page} 
                    setPage={setPage}
                    checked={checked}
                    setChecked={setChecked}
                />
            </> : <Loading />}
		</Box>
	);
}

export default LogPage;