import { useEffect, useState } from "react";
import axios from "axios";
import API from 'api';


export default function useSearchAppLog(page, { userId, code, division }, trigger) {
    const [ rows, setRows ] = useState([]);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
 
    useEffect(() => {
        setRows([]);
    }, [ division, userId, code, page, trigger ]);

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);
        const params = { 
            division,
            code,
            userId,
            page,
        };
        API.get('/admin/search-app-logs', { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setTotalCount(data.totalCount);
            setRowsPerPage(data.rowsPerPage);
            setRows(data.rows);
            setMayMore(data.rows.length > 0);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ userId, code, page, division, trigger ]);

    return { loading, error, rows, mayMore, totalCount, rowsPerPage };
}