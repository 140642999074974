import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import Chart from 'chart.js/auto';


const PriceChart = ({ labels, memberPriceInfo, itemPriceInfo, actionPriceInfo, options }) => {

    useEffect(() => {
        const ctx = document.getElementById('pricechart');

        const data = {
            labels,
            datasets: [
                {
                    label: "멤버 - 등급1",
                    data: memberPriceInfo.grade_1,
                    stack: 'Stack 0',
                },
                {
                    label: "멤버 - 등급2",
                    data: memberPriceInfo.grade_2,
                    stack: 'Stack 0',
                },
                {
                    label: "멤버 - 등급3",
                    data: memberPriceInfo.grade_3,
                    stack: 'Stack 0',
                },
                {
                    label: "아이템 - 등급1",
                    data: itemPriceInfo.grade_1,
                    stack: 'Stack 1',
                },
                {
                    label: "아이템 - 등급2",
                    data: itemPriceInfo.grade_2,
                    stack: 'Stack 1',
                },
                {
                    label: "아이템 - 등급3",
                    data: itemPriceInfo.grade_3,
                    stack: 'Stack 1',
                },
                {
                    label: "액션 - 등급1",
                    data: actionPriceInfo.grade_1,
                    stack: 'Stack 2',
                },
                {
                    label: "액션 - 등급2",
                    data: actionPriceInfo.grade_2,
                    stack: 'Stack 2',
                },
                {
                    label: "액션 - 등급3",
                    data: actionPriceInfo.grade_3,
                    stack: 'Stack 2',
                },
            ],
        };
        
        const config = {
            type: 'bar',
            data: data,
            options,
        };

        const chart = new Chart(ctx, config);

        return (() => {
            chart.destroy();
        })
    }, [ memberPriceInfo, itemPriceInfo, actionPriceInfo ]);

	return (
		<Box>
            <canvas id="pricechart"></canvas>
		</Box>
	);
}

export default PriceChart;