import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/system";


const AButton = ({ children, ...props }) => {
    return (
        <MuiButton { ...props } disableElevation>{children}</MuiButton>
    );
}

const Button = styled(AButton)(({theme}) => ({
    '&.MuiButton-containedPrimary': {
        color: 'white'
    }
}));


export default Button;