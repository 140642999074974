import React, { useContext } from 'react';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import TablePaginationActions from 'components/TablePaginationActions';


export default function ReferrerTable({ rows, totalCount, rowsPerPage, page, setPage }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table style={{ minWidth: '650px' }} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>링크</StyledTableCell>
                            <StyledTableCell>대상</StyledTableCell>
                            <StyledTableCell>접속수</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, idx) => (
                            <StyledTableRow key={idx}>
                                <StyledTableCell style={{ maxWidth: '360px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{row.referrer}</StyledTableCell>
                                <StyledTableCell style={{ maxWidth: '360px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{row.requestUri}</StyledTableCell>
                                <StyledTableCell>{row.count}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    );
}