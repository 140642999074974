import React, { useContext } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Link as MuiLink, Button } from '@mui/material';
import { datetime_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { Link } from 'react-router-dom';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import TablePaginationActions from 'components/TablePaginationActions';


export default function PlaceTable({ rows, totalCount, rowsPerPage, page, setPage, mode, onSelect }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const { showSpaceDetail, showSnackbar, showAlert } = useContext(AppContext);

    const checkPlaceItem = (place) => () => {
        const body = { division: 'place', placeId: place.id, makeUpdate: false }
        API.post('/data/assert-place-items', body)
        .then((result) => {
            if (result.mismatch) showAlert('PlaceItem 의 amount 와 ActionObject 의 hAmount 또는 tAmount 가 일치하지 않습니다. (logs 테이블 확인바람)');
            else showSnackbar('일치합니다.');
        })
    }

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table style={{ minWidth: '650px' }} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>일련번호</StyledTableCell>
                            <StyledTableCell>플레이스</StyledTableCell>
                            {mode !== 'select' && <StyledTableCell>스페이스</StyledTableCell>}
                            {mode !== 'select' && <StyledTableCell>생성일시</StyledTableCell>}
                            {mode !== 'select' && <StyledTableCell>관리</StyledTableCell>}
                            {mode === 'select' && <StyledTableCell>선택</StyledTableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th">{row.id}</StyledTableCell>
                                <StyledTableCell>{row.name}</StyledTableCell>
                                {mode !== 'select' && <StyledTableCell><MuiLink component={Link} to="#" onClick={() => { showSpaceDetail(row.spaceId); }}>{row.spaceName}</MuiLink></StyledTableCell>}
                                {mode !== 'select' && <StyledTableCell>{datetime_pipe(row.createdAt)}</StyledTableCell>}
                                {mode !== 'select' && <StyledTableCell><Button variant="outlined" size="small" onClick={checkPlaceItem(row)}>Check PlaceItem</Button></StyledTableCell>}
                                {mode === 'select' && <StyledTableCell><Button variant="outlined" size="small" onClick={() => { onSelect(row); }}>선택</Button></StyledTableCell>}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    );
}