import React, { useState, useEffect } from 'react';
import { Typography, Paper, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import API from 'api';
import VisitCountChart from './VisitCountChart';
import colors from 'constants/colors';



const VisitCount = () => {
	const [ yearmonth, setYearmonth ] = useState(new Date());
	
	const [ labels, setLabels ] = useState();
    const [ data, setData ] = useState();
    useEffect(() => {
		const params = {
			year: yearmonth.getFullYear(),
			month: yearmonth.getMonth() + 1,
		};

        API.get('/statistics/visit-count-statistics', { params })
        .then((result) => {
			setLabels(result.rows.map(el => el.targetDate));
            setData(result.rows.map(el => el.count));
        })
    }, [ yearmonth ]);

	if (!data) return null;

	return (
		<Paper className="pagePadding" sx={{ p: 2 }}>
            <Typography variant="h5">일간 방문 횟수</Typography>
			<Typography variant="body" style={{ color: colors.textSecondary, marginTop: '16px', marginBottom: '24px' }}>로그인유무와 관계없이 한번이라도 접속한 횟수</Typography>

			<div>
				<DatePicker
					label="시작일자"
					openTo={'month'}
					views={['month', 'year']}
					value={yearmonth}
					onChange={(newValue) => {
						setYearmonth(newValue);
					}}
					renderInput={(params) => <TextField sx={{ width: { xs: '120px', md: 'auto', marginTop: '24px' } }} size="small" {...params} onKeyDown={e => e.preventDefault()} />}
				/>
			</div>
			

            <VisitCountChart
				labels={labels}
				dataset={data}
				div={'visit-count-statistics'}
				options={{
					scales: {
						x: {
							title: {
								display: true,
								text: '월',
							}
						},
						y: {
							suggestedMin: 0,
							suggestedMax: 50,
							title: {
								display: true,
								text: '명'
							},
						}
					},
				}}
			/>
        </Paper>
	);
}

export default VisitCount;