import React, { useContext } from 'react';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Link as MuiLink, Button } from '@mui/material';
import { actioncode_color_pipe, actioncode_pipe, bucket_name_pipe, datetime_pipe, number_format_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { Link } from 'react-router-dom';
import TablePaginationActions from 'components/TablePaginationActions';
import { AppContext } from 'contexts/app.context';


export default function ActionTable({ rows, totalCount, rowsPerPage, page, setPage }) {
    const {  showAlert } = useContext(AppContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onLogClick = (row) => () => {
        showAlert(row.logText || '로그가 없습니다.');
    }

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>순번</StyledTableCell>
                            <StyledTableCell>objId</StyledTableCell>
                            <StyledTableCell>스페이스</StyledTableCell>
                            <StyledTableCell>구분</StyledTableCell>
                            <StyledTableCell>아이템</StyledTableCell>
                            <StyledTableCell>속성</StyledTableCell>
                            <StyledTableCell>요청자</StyledTableCell>
                            <StyledTableCell>수행자</StyledTableCell>
                            <StyledTableCell>입출고명세서</StyledTableCell>
                            <StyledTableCell>플레이스</StyledTableCell>
                            <StyledTableCell>플레이스(이동)</StyledTableCell>
                            <StyledTableCell>거래처</StyledTableCell>
                            <StyledTableCell align='right'>개수</StyledTableCell>
                            <StyledTableCell align='right'>남은개수</StyledTableCell>
                            <StyledTableCell align='right'>tAmount</StyledTableCell>
                            <StyledTableCell>요청일시</StyledTableCell>
                            <StyledTableCell>수행일시</StyledTableCell>
                            <StyledTableCell>관리</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th">{row.order}</StyledTableCell>
                                <StyledTableCell>{row.id}</StyledTableCell>
                                <StyledTableCell><MuiLink component={Link} to="/">{row.spaceId}</MuiLink></StyledTableCell>
                                <StyledTableCell sx={{ color: actioncode_color_pipe(row.code) }}>{actioncode_pipe(row.code)}</StyledTableCell>
                                <StyledTableCell>{row.item.name}{row.item.code && ` [${row.item.code}]`} ({row.item.id})</StyledTableCell>
                                <StyledTableCell>{row.item.valSummaries || '-'}</StyledTableCell>
                                <StyledTableCell>{row.user.nickname}</StyledTableCell>
                                <StyledTableCell>{row.executor?.nickname}</StyledTableCell>
                                <StyledTableCell>{row.bucket.id ? `${bucket_name_pipe(row.bucket)}(${row.bucket.id})` : '-'}</StyledTableCell>
                                <StyledTableCell>{row.place.name ? `${row.place.name} (${row.place.id})` : '-'}</StyledTableCell>
                                <StyledTableCell>{row.toPlace.name ? `${row.toPlace.name} (${row.toPlace.id})` : '-'}</StyledTableCell>
                                <StyledTableCell>{row.trader.name || '-'}</StyledTableCell>
                                <StyledTableCell align='right'>{number_format_pipe(row.amount)}</StyledTableCell>
                                <StyledTableCell align='right'>{number_format_pipe(row.hAmount)}</StyledTableCell>
                                <StyledTableCell align='right'>{number_format_pipe(row.tAmount) || '-'}</StyledTableCell>
                                <StyledTableCell>{datetime_pipe(row.createdAt)}</StyledTableCell>
                                <StyledTableCell>{datetime_pipe(row.processedAt)}</StyledTableCell>
                                <StyledTableCell><Button size='small' onClick={onLogClick(row)}>로그</Button></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    );
}