import React, { useState } from 'react';
import { Box, Typography, Link as MuiLink, TextField, MenuItem } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import useSearchAlarm from 'api/alarm/useSearchAlarm';
import Title from 'components/Title';
import CircularButton from 'components/CircularButton';
import DataWrapper from 'components/DataWrapper';
import AlarmTable from './AlarmTable';
import { alarms } from 'constants/alarm.constants';



const AlarmListPage = () => {
	const navigate = useNavigate();

	const [ page, setPage ] = useState(0);
	const [ params, setParams ] = useState({});
	
	const [ userId, setUserId ] = useState('');
	const [ code, setCode ] = useState('');
	
	const { loading, error, rows, mayMore, totalCount, rowsPerPage } = useSearchAlarm(page, params);

	const handleInitClick = () => {
		setUserId('');
		setCode('');
        setParams({});
        setPage(0);
    }

    const handleSearchClick = () => {
        setParams({
            userId,
			code,
        });
        setPage(0);
    }

	return (
		<Box>
			<Title>알림</Title>

			<Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
				<TextField
					size='small'
					sx={{ width: '100px' }}
					label={'UserId'}
					variant="outlined"
					onChange={(event) => { setUserId(event.target.value); }}
					value={userId || ''}
				/>

				<TextField
					select
					size='small'
					sx={{ ml: 2, width: '240px' }}
					label={'코드'}
					variant="outlined"
					onChange={(event) => { setCode(event.target.value); }}
					value={code || ''}
				>
					{alarms.map(alarm => <MenuItem key={alarm.code} value={alarm.code}>{alarm.name}</MenuItem>)}
				</TextField>
				
				<CircularButton style={{ marginLeft: '8px' }} variant='contained' onClick={handleSearchClick}>검색</CircularButton>
			</Box>

			<DataWrapper
				loading={loading}
				fetched={true}
			>
				<AlarmTable
					rows={rows}
					totalCount={totalCount}
					rowsPerPage={rowsPerPage}
					page={page}
					setPage={setPage}
				/>
			</DataWrapper>
		</Box>
	);
}

export default AlarmListPage;