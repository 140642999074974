import React, { useMemo, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import Title from 'components/Title';
import CircularButton from 'components/CircularButton';
import { useSearchParams } from 'react-router-dom';
import DataListWrapper from 'components/DataListWrapper';
import SpaceSelectionModal from 'views/space/SpaceSelectionModal';
import colors from 'constants/colors';
import PlaceTable from './PlaceTable';
import useSearchPlace from 'api/place/useSearchPlace';


const PlaceListPage = () => {
	const [ searchParams, setSearchParams ] = useSearchParams();

	const [ page, setPage ] = useState(0);	
	const [ keyword, setKeyword ] = useState('');
    const [ spaceId, setSpaceId ] = useState();

	const params = useMemo(() => {
		const page = searchParams.get('page') || 0;
		const keyword = searchParams.get('keyword') || '';
        const spaceId = searchParams.get('spaceId');

		setPage(+page);
		setKeyword(keyword);
        setSpaceId(spaceId);

		return ({
			page,
			keyword,
            spaceId,
		})
	}, [ searchParams ]);

	const { loading, error, rows, mayMore, totalCount, rowsPerPage, fetched } = useSearchPlace(page, params);

    const handleSearch = (event) => {
		event.preventDefault();

		setSearchParams({
			page: 0,
			keyword,
            spaceId: spaceId || '',
		});
    }

	const [ selectionModalOpen, setSelectionModalOpen ] = useState(false);

	return (
		<Box>
			<Title>플레이스</Title>

			<form onSubmit={handleSearch}>
				<Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
					<TextField
						size='small'
						sx={{ minWidth: '180px' }}
						label={'키워드'}
						variant="outlined"
						onChange={(event) => { setKeyword(event.target.value); }}
						value={keyword}
					/>

                    {!spaceId ? <Button sx={{ ml: 1, color: colors.textSecondary }} variant='outlined' onClick={() => { setSelectionModalOpen(true); }}>스페이스 선택</Button>
                    : <Button sx={{ ml: 1, color: colors.secondary }} variant='outlined' onClick={() => { setSpaceId(null); }}>선택 해제</Button>}
					
					<CircularButton style={{ marginLeft: '8px' }} variant='contained' type="submit">검색</CircularButton>
				</Box>
			</form>

			<DataListWrapper
				loading={loading}
				fetched={fetched}
				length={rows?.length}
				nodata={'플레이스가 없습니다.'}
			>
				<PlaceTable
					rows={rows}
					totalCount={totalCount}
					rowsPerPage={rowsPerPage}
					page={page}
					setPage={setPage}
				/>
			</DataListWrapper>

            <SpaceSelectionModal open={selectionModalOpen} setOpen={setSelectionModalOpen} handleSelectSpace={space => setSpaceId(space.id)} />
		</Box>
	);
}

export default PlaceListPage;