import React, { useMemo, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import useReferrerStatistics from 'api/statistics/useReferrerStatistics';
import Title from 'components/Title';
import CircularButton from 'components/CircularButton';
import DataListWrapper from 'components/DataListWrapper';
import { useSearchParams } from 'react-router-dom';
import ReferrerTable from './ReferrerTable';


const ReferrerPage = () => {
	// const [ searchParams, setSearchParams ] = useSearchParams();

	const [ page, setPage ] = useState(0);
    const [ from, setFrom ] = useState();
    const [ to, setTo ] = useState();
    const [ params, setParams ] = useState({});

    const { loading, error, fetched, rows, mayMore, count, rowsInPage } = useReferrerStatistics(page, params);
	
    const handleInitClick = () => {
        from('');
        to('');
        setParams({});
        setPage(0);
    }

    const handleSearch = (event) => {
		event.preventDefault();

		setParams({
            from,
            to,
        });
        setPage(0);
    }



	return (
		<Box>
			<Title>접속통계</Title>

			<form onSubmit={handleSearch}>
				<Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
					<TextField
						size='small'
                        type="date"
						sx={{ minWidth: '180px' }}
						label={'시작일'}
						variant="outlined"
						onChange={(event) => { setFrom(event.target.value); }}
						value={from}
					/>

                    <TextField
                        style={{ marginLeft: 8 }}
						size='small'
                        type="date"
						sx={{ minWidth: '180px' }}
						label={'종료일'}
						variant="outlined"
						onChange={(event) => { setTo(event.target.value); }}
						value={to}
					/>
					
					<CircularButton style={{ marginLeft: '8px' }} variant='contained' type="submit">검색</CircularButton>
				</Box>
			</form>

            <Typography variant='body2' style={{ color: '#aaaaaa' }}>웹 사이트에 최초 접속경로를 비교하기 위한 목적으로, 방문횟수와는 관계없습니다. (세션유효기간: 1개월)</Typography>
            <Typography variant='body2' style={{ color: '#aaaaaa' }}>시작일, 종료일을 입력하지 않으면, 최근 3개월기준으로 통계합니다.</Typography>
			

			<DataListWrapper
				loading={loading}
				fetched={fetched}
				length={rows?.length}
				nodata={'데이터가 없습니다.'}
			>
				<ReferrerTable
					rows={rows}
					totalCount={count}
					rowsPerPage={rowsInPage}
					page={page}
					setPage={setPage}
				/>
			</DataListWrapper>
		</Box>
	);
}

export default ReferrerPage;