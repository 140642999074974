import React, { useState, useEffect, useContext, useRef, useMemo, Fragment } from 'react';
import { Box, Typography, Link as MuiLink, Dialog, DialogContent, DialogContentText, DialogActions, TextField, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import Button from 'components/Button';
import Title from 'components/Title';
import { Label, Content, InputRow, InputContent } from 'components/KeyValueData';
import { format, formatISO } from 'date-fns';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import Loading from 'components/Loading';


const SettingPage = () => {
	const navigate = useNavigate();

    const { showSnackbar, showAlert } = useContext(AppContext);

    const loadData = () => {
        API.get('/admin/settings-in-admin-page')
        .then(setSettings)
    }

    const [ settings, setSettings ] = useState();
    useEffect(loadData, []);

    // 서버점검중
    const maintenanceStateText = useMemo(() => {
        let result = '-';
        if (settings) {
            const item = settings.find(el => el.code === 'server_maintenance_until');
            if (item && item.value) {
                result = `현재 서버점검중, 종료일시: ${item.value}`;
            }
        }
        return result;
    }, [ settings ]);

    const [ maintenanceModalOpen, setMaintenanceModalOpen ] = useState(false);
    const [ maintenanceDatetime, setMaintenanceDatetime] = useState();
    const handleServerMaintenance = () => {
        if (!maintenanceDatetime) return showAlert('서버점거중 종료일시를 입력하세요.');

        maintenanceDatetime.setSeconds(0);
        const value = formatISO(maintenanceDatetime);
        const body = { settings: { code: 'server_maintenance_until', value } };
        API.put('/admin/settings', body)
        .then(() => {
            showSnackbar(`서버점검중이 시작되었습니다.`);
            loadData();
        })
        .finally(() => { setMaintenanceModalOpen(false); })
    }

    const handleServerMaintenanceOff = () => {
        const body = { settings: { code: 'server_maintenance_until', value: null } };
        API.put('/admin/settings', body)
        .then(() => { 
            showSnackbar('서버점검중 상태를 종료했습니다.');
            loadData();
        });
    }
    // end: 서버점검중

    // 전체매시지
    const globalMessageText = useMemo(() => {
        let result = '-';
        if (settings) {
            const item = settings.find(el => el.code === 'global_message');
            if (item && item.value) {
                result = item.value;
            }
        }
        return result;
    }, [ settings ]);

    const [ globalMessageModalOpen, setGlobalMessageModalOpen ] = useState(false);
    const [ globalMessage, setGlobalMessage] = useState('');

    const handleUpdateGlobalMessage = () => {
        const body = { settings: { code: 'global_message', value: globalMessage } };
        API.put('/admin/settings', body)
        .then(() => {
            showSnackbar(`설정했습니다.`);
            loadData();
        })
        .finally(() => { setGlobalMessageModalOpen(false); })
    }
    // end: 전체메시지

    if (!settings) return (<Loading />);

	return (
		<Box>
            {/* 서버점검 */}
            <Box>
                <Title>설정</Title>
                
                <div style={{ borderTop: '3px solid #cccccc' }}>
                    <Box sx={{ display: 'flex', borderBottom: '1px solid #cccccc' }}>
                        <Label>
                            <Typography variant="body1">서버점검중 상태</Typography>
                        </Label>
                        <Content style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ color: colors.textSecondary, flex: 1 }}>{maintenanceStateText}</Typography>
                            <Button type="button" color="primary" size="small" variant="outlined" onClick={() => { setMaintenanceModalOpen(true); }}>설정하기</Button>
                            {maintenanceStateText !== '-' && <Button sx={{ ml: 1 }} type="button" color="secondary" size="small" variant="outlined" onClick={handleServerMaintenanceOff}>해제하기</Button>}
                        </Content>

                        <Dialog
                            open={maintenanceModalOpen}
                            onClose={() => { setMaintenanceModalOpen(false); }}
                        >
                            <DialogTitle>서버점검시간 종료 설정</DialogTitle>
                            <DialogContent style={{ minWidth: '400px' }}>
                                <DialogContentText sx={{ whiteSpace: 'pre-line', mb: 3 }}>{'설정하는 즉시 서버점검상태가 됩니다.\n서버점검중 상태에서는 백엔드 기능이 정지 되지 않고, 사용자가 최초에 서버점검중이라는 메시지를 보게 됩니다.'}</DialogContentText>
                                <DateTimePicker
                                    label="서버점검 종료일시"
                                    value={maintenanceDatetime}
                                    onChange={setMaintenanceDatetime}
                                    renderInput={(params) => <TextField sx={{ width: { xs: '120px', md: 'auto' } }} size="small" {...params} onKeyDown={e => e.preventDefault()} />}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleServerMaintenance} color="primary">확인</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>

                    <Box sx={{ display: 'flex', borderBottom: '1px solid #cccccc' }}>
                        <Label>
                            <Typography variant="body1">전체메시지</Typography>
                        </Label>
                        <Content style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ color: colors.textSecondary, flex: 1 }}>{globalMessageText}</Typography>
                            <Button type="button" color="primary" size="small" variant="outlined" onClick={() => { setGlobalMessageModalOpen(true); }}>설정하기</Button>
                        </Content>

                        <Dialog
                            open={globalMessageModalOpen}
                            onClose={() => { setGlobalMessageModalOpen(false); }}
                        >
                            <DialogTitle>글로벌 메시지 설정</DialogTitle>
                            <DialogContent style={{ minWidth: '400px' }}>
                                <DialogContentText sx={{ whiteSpace: 'pre-line', mb: 3 }}>{'사용자가 최초에 글로벌 메시지를 보게 됩니다. 설정을 해제하려면 공란으로 업데이트하세요.'}</DialogContentText>
                                <TextField 
                                    sx={{ width: '100%' }}
                                    label="메시지" 
                                    variant="outlined"
                                    rows={3}
                                    multiline={true}
                                    value={globalMessage}
                                    onChange={(event) => setGlobalMessage(event.target.value)}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleUpdateGlobalMessage} color="primary">확인</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </div>
            </Box>
		</Box>
	);
}

export default SettingPage;