import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import API from 'api';
import Loading from 'components/Loading';
import DataUsageChart from './DataUsageChart';
import PriceChart from './PriceChart';
import Title from 'components/Title';


const DataUsagePage = () => {
    const { spaceId } = useParams();

    const [ data, setData ] = useState();
    useEffect(() => {
        API.get(`/price/bill-statistics/${spaceId}`)
        .then(result => setData(result));
    }, [ spaceId ]);

    if (!data) return (<Loading />);

	return (
		<Box>
            <Title style={{ marginBottom: '32px' }}>{`스페이스 데이터 사용량 - ${data.space.name}`}</Title>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DataUsageChart
                        labels={data.labels}
                        dataset={data.memberCountInfo}
                        div={'datausage_member'}
                        options={{
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: '월',
                                    }
                                },
                                y: {
                                    suggestedMin: 0,
                                    suggestedMax: 10,
                                    title: {
                                        display: true,
                                        text: '명'
                                    },
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    text: '데이터사용: 회원'
                                },
                                legend: {
                                    display: false,
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DataUsageChart
                        labels={data.labels}
                        dataset={data.itemCountInfo}
                        div={'datausage_item'}
                        options={{
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: '월',
                                    }
                                },
                                y: {
                                    suggestedMin: 0,
                                    suggestedMax: 50,
                                    title: {
                                        display: true,
                                        text: '개수'
                                    },
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    text: '데이터사용: 아이템'
                                },
                                legend: {
                                    display: false,
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DataUsageChart
                        labels={data.labels}
                        dataset={data.actionCountInfo}
                        div={'datausage_action'}
                        options={{
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: '월',
                                    }
                                },
                                y: {
                                    suggestedMin: 0,
                                    suggestedMax: 50,
                                    title: {
                                        display: true,
                                        text: '개수'
                                    },
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    text: '데이터사용: 액션'
                                },
                                legend: {
                                    display: false,
                                }
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <PriceChart
                        labels={data.labels}
                        memberPriceInfo={data.memberPriceInfo}
                        itemPriceInfo={data.itemPriceInfo}
                        actionPriceInfo={data.actionPriceInfo}
                        options={{
                            scales: {
                                x: {
                                    stacked: true,
                                    title: {
                                        display: true,
                                        text: '월',
                                    },
                                },
                                y: {
                                    suggestedMin: 0,
                                    suggestedMax: 20000,
                                    stacked: true,
                                    title: {
                                        display: true,
                                        text: '원'
                                    },
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    text: '가격'
                                }
                            }
                        }}
                    />
                </Grid>

                {/* 가격정책 */}
                <Grid item xs={6}>
                    <Typography variant='h6'>가격책정</Typography>
                </Grid>

                {/* 요약설명 */}
                <Grid item xs={6}>
                    <Typography variant='h6'>요약</Typography>
                </Grid>    
            </Grid>
		</Box>
	);
}

export default DataUsagePage;