import Axios from 'axios';


const API = Axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: process.env.NODE_ENV === 4000,
	withCredentials: true,		// 크로스도메인 일 경우 cookie 를 보내기 위해 true 를 해놓아야 함
});

export function setupInterceptor({ showAlert, showDialog, location, navigate, setLoginRedirectURL }) {
	console.info('api interceptor setup.');

	// request interceptor
	API.interceptors.request.use(function (config) {
        const headers = {
            'Accept': 'application/json, text/plain',
			'os': 'web',
        };

		config.headers = headers;

		if (process.env.REACT_APP_MODE === 'test') {
			console.log('API Request', config.url, config);
		}
		return config;
	});

	// response interceptor
	API.interceptors.response.use((response) => {
		if (process.env.REACT_APP_MODE === 'test') {
			console.log('API Response:', response.config.url, response);
		}
		return response?.data;
	}, 
	(error) => {
		if (error.response) {
			if (process.env.REACT_APP_MODE === 'test') {
				console.log(error.response.status, error.response.data);
			}
			
			if (error.response.status === 401) {
				const dialogData = {
					title: '사용자 인증',
					message: '인증이 필요합니다. 로그인 페이지로 이동하시겠습니까?',
					first: {
						onClick: () => {
							setLoginRedirectURL(location.pathname);
							navigate('/auth/signin');
						}
					},
					second: true,
				}
				showDialog(dialogData);
			}
			else if (error.response.status === 400) {
				showAlert('잘못된 요청입니다.');
            }
            else if (error.response.status === 404) {
				showAlert('요청하신 경로는 존재하지 않습니다.');
            }
			else if (error.response.status === 403) {
				showAlert('권한이 없습니다.');
			} 
            else if (error.response.status === 500) {
				const code = error.response.data?.code;

				if (code) {
					if (code.startsWith('yup') && error.response.data.errors?.length > 0) {
						const i_error = error.response.data.errors[0];
						switch (i_error.code) {
							case 'yup_field_required':
								showAlert(`필수 입력값을 입력해주세요. [${i_error.values.path}]`);
								break;
							default: showAlert(error.response.data.message);
						}
					}
					else {
						switch(code) {
							case 'user_state_pending':
								showAlert('인증이 필요합니다. 이메일 인증을 하셨을까요?');
								break;
							default:
								const error = new Error('에러가 발생했습니다.');
								error.code = code;
								throw error;
						}
					}
				}
				else {
					let message = error.response.data.message;
					showAlert(message || '알 수 없는 에러가 발생했습니다. 개발자에게 문의해주세요.');
				}
            }
			else {
				showAlert('에러가 발생했습니다. 고객센터에 문의하여 주세요.');
			}
		} 
		else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			if (error.code === 'ECONNABORTED') {
                showAlert('시간이 초과되었습니다. 다시 시도해보세요.');
            }
            else {
                console.log(error.request);
                showAlert('앱에서 알 수 없는 에러가 발생했습니다.');
            }
		} 
		else {
			console.log('Something happened in setting up the request that triggered an Error', error);
			if (error.code !== 'ERR_CANCELED') {
				if (error.message) doAlert(error.message);
			}
		}

		return Promise.reject(error);
	});
}

const doAlert = (message) => {
	if (process.env.NODE_ENV === 'production') {
		const spans = message.split(' ');
		if (spans.length > 0) {
			const firstSpan = spans[0];
			if ((/^[a-z0-9]+$/i.test(firstSpan))) {
				message = '에러가 발생했습니다. 고객센터에 문의해주세요.';
			}
		}
	}
	alert(message);
}





export default API;