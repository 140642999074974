import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import Chart from 'chart.js/auto';


const ActionsGroupbyWeekChart = ({ rows }) => {

    const actionDataset = useMemo(() => {
        const data = rows.map(el => 
            ({
                xAsis: el.year + '-' + el.week,
                count: +el.count,
            })
        );

        return ({
            label: '액션',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data,
        });
    }, [ rows ]);
    
    useEffect(() => {
        const ctx = document.getElementById('myChart');

        const data = {
            datasets: [ actionDataset ],
        };
        
        const config = {
            type: 'line',
            data: data,
            options: {
                parsing: {
                    xAxisKey: 'xAsis',
                    yAxisKey: 'count',
                },
            }
        };

        const chart = new Chart(ctx, config);

        return (() => {
            chart.destroy();
        })
    }, [ actionDataset ]);

	return (
		<Box>
            <canvas id="myChart"></canvas>
		</Box>
	);
}

export default ActionsGroupbyWeekChart;