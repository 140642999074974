import ApiTestPage from './views/development/api/ApiTestPage';
import { MainLayout } from './layouts/MainLayout';
import TypographyPage from 'views/development/view/TypographyPage';
import ColorPage from 'views/development/view/ColorPage';
import ButtonPage from 'views/development/view/ButtonPage';
import TablePage from 'views/development/view/TablePage';
import InputPage from 'views/development/view/InputPage';
import KeyValuePage from 'views/development/view/KeyValuePage';
import GuidePage from 'views/development/view/GuidePage';
import ModalPage from 'views/development/view/ModalPage';
import App from 'App';
import Page404 from 'components/Page404';
import TransferList from 'views/development/view/TransferListPage';
import EnhancedTablePage from 'views/development/view/EnhancedTablePage';
import PasswordSigninPage from 'views/auth/signin/PasswordSigninPage';
import HomePage from 'views/HomePage';
import StatisticsPage from 'views/statistics/StatisticsPage';
import TicketPage from 'views/ticket/TicketPage';
import AlarmListPage from 'views/alarm/AlarmListPage';
import LogPage from 'views/statistics/LogPage';
import SpaceListPage from 'views/space/list/SpaceListPage';
import UserListPage from 'views/user/list/UserListPage';
import TraderListPage from 'views/trader/list/TraderListPage';
import PlaceListPage from 'views/place/list/PlaceListPage';
import SettingPage from 'views/setting/SettingPage';
import ItemListPage from 'views/item/list/ItemListPage';
import ActionListPage from 'views/action/list/ActionListPage';
import SpaceDetailPage from 'views/space/detail/SpaceDetailPage';
import DataUsagePage from 'views/space/data-usage/DataUsagePage';
import ReferrerPage from 'views/statistics/referrer/ReferrerPage';
import HostReferrerPage from 'views/statistics/referrer/HostReferrerPage';
import LastAccessPage from 'views/statistics/last-access/LastAccessPage';

  
const routes = [
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "auth",
                children: [
                    {
                        path: "password-signin",
                        element: <PasswordSigninPage />,
                    },
                ]
            },
            {
                element: <MainLayout />,
                children: [
                    {
                        path: 'statistics',
                        breadcrumb: "통계",
                        children: [
                            {
                                path: 'log',
                                element: <LogPage />,
                                breadcrumb: "로그",
                            },
                            {
                                path: 'last-access',
                                element: <LastAccessPage />,
                                breadcrumb: "최근접속통계",
                            },
                            {
                                path: 'referrer',
                                element: <ReferrerPage />,
                                breadcrumb: "접속통계",
                            },
                            {
                                path: 'host-referrer',
                                element: <HostReferrerPage />,
                                breadcrumb: "호스트 접속통계",
                            },
                            {
                                index: true,
                                element: <StatisticsPage />,
                                breadcrumb: "계기판",
                            },
                        ]
                    },
                    {
                        path: "ticket",
                        breadcrumb: "문의",
                        children: [
                            {
                                index: true,
                                element: <TicketPage />,
                                breadcrumb: "문의",
                            }
                        ]
                    },
                    {
                        path: 'data',
                        breadcrumb: "데이터",
                        children: [
                            {
                                path: 'user',
                                breadcrumb: "사용자",
                                element: <UserListPage />
                            },
                            {
                                path: 'space',
                                breadcrumb: "스페이스",
                                children: [
                                    {
                                        path: 'data-usage/:spaceId',
                                        element: <DataUsagePage />
                                    },
                                    {
                                        path: ':spaceId',
                                        element: <SpaceDetailPage />
                                    },
                                    {
                                        index: true,
                                        element: <SpaceListPage />,
                                    }
                                ],
                            },
                            {
                                path: 'trader',
                                breadcrumb: "거래처",
                                element: <TraderListPage />
                            },
                            {
                                path: 'place',
                                breadcrumb: "플레이스",
                                element: <PlaceListPage />
                            },
                            {
                                path: 'item',
                                breadcrumb: "아이템",
                                element: <ItemListPage />
                            },
                            {
                                path: 'action',
                                breadcrumb: "액션",
                                element: <ActionListPage />
                            },
                            {
                                path: 'alarm',
                                breadcrumb: "알림",
                                element: <AlarmListPage />
                            },
                        ]
                    },
                    {
                        path: 'setting',
                        breadcrumb: "설정",
                        element: <SettingPage />
                    },
                    {
                        path: "development",
                        children: [
                            {
                                path: "api",
                                children: [
                                    {
                                        path: "api",
                                        element: <ApiTestPage />,
                                    }
                                ]
                            },
                            {
                                path: 'view',
                                children: [
                                    {
                                        path: "typography",
                                        element: <TypographyPage />,
                                    },
                                    {
                                        path: "color",
                                        element: <ColorPage />,
                                    },
                                    {
                                        path: "button",
                                        element: <ButtonPage />,
                                    },
                                    {
                                        path: "table",
                                        element: <TablePage />,
                                    },
                                    {
                                        path: "enhanced-table",
                                        element: <EnhancedTablePage />,
                                    },
                                    {
                                        path: "input",
                                        element: <InputPage />,
                                    },
                                    {
                                        path: "key-value",
                                        element: <KeyValuePage />,
                                    },
                                    {
                                        path: "modal",
                                        element: <ModalPage />,
                                    },
                                    {
                                        path: "guide",
                                        element: <GuidePage />,
                                    },
                                    {
                                        path: "transferList",
                                        element: <TransferList />,
                                    },
                                ]
                            }
                        ]
                    },
                    // {
                    //     index: true,
                    //     element: <HomePage />,
                    //     breadcrumb: "홈",
                    // }
                ]
            },
            {
                index: true,
                element: <HomePage />,
            }
        ]
    },
    {
        path: "*",
        element: <Page404 />
    },
];


export default routes;