import React, { useState, useEffect, useContext, useRef, useMemo, Fragment } from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import Button from 'components/Button';
import Space from './Space';



const SpaceDetailPage = () => {
	const navigate = useNavigate();

	return (
		<Box>
            <Space />
		</Box>
	);
}

export default SpaceDetailPage;