import React, { useState, useEffect } from 'react';
import { Typography, Paper, TextField } from '@mui/material';
import API from 'api';
import colors from 'constants/colors';



const LastAccessPage = () => {
    const [ data, setData ] = useState();
    useEffect(() => {
        API.get('/statistics/last-access-statistics')
        .then((result) => {
            setData(result);
        })
    }, []);

    if (!data) return null;

	return (
		<Paper className="pagePadding" sx={{ p: 2 }}>
            <Typography variant="h5">최근 접속 횟수</Typography>
            <Typography variant="body" style={{ color: colors.textSecondary, marginTop: '16px', marginBottom: '24px' }}>로그인한 사용자 대상</Typography>

            <div style={{ marginTop: '24px' }}>
                {data.map(item => <div key={item.diffDate} style={{ display: 'flex', marginBottom: '8px' }}>
                    <Typography variant='body1' style={{ width: '200px' }}>{item.label} ({item.diffDate}) </Typography>
                    <Typography variant='body1' style={{ width: '80px', textAlign: 'right' }}>{item.count}</Typography>
                </div>)}
            </div>
        </Paper>
	);
}

export default LastAccessPage;