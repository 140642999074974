import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppContextProvider } from 'contexts/app.context';
import { MemoryContextProvider } from 'contexts/memory.context';
import { AuthContextProvider } from 'contexts/auth.context';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import routes from 'Routes';


const theme = createTheme({
    typography: {
        fontSize: 12,
    },
});

function AppRoutes() {
    const element = useRoutes(routes);
    return element;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <AppContextProvider>
                <AuthContextProvider>
                    <MemoryContextProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <AppRoutes />
                        </LocalizationProvider>
                    </MemoryContextProvider>
                </AuthContextProvider>
            </AppContextProvider>
        </BrowserRouter>
    </ThemeProvider>
);