import React, { useState } from 'react';
import { Checkbox, TextField, FormControlLabel, Radio, FormControl, FormLabel, RadioGroup, MenuItem, Switch, Paper, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';


const InputPage = () => {
    const [ checked, setChecked ] = useState(true);
    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    const [ gender, setGender ] = useState('female');
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    }

    const [ bank, setBank ] = useState('kb');

    const [ agree, setAgree ] = useState(true);
    const handleAgreeChange = () => {
        setAgree(val => !val);
    }

	return (
		<Box>
            <form noValidate autoComplete="off">
                <div>
                    <TextField 
                        label="Outlined" 
                        variant="outlined" 
                        size="small"
                    />
                </div>
                
                <div style={{ marginTop: '32px' }}>
                    <FormControlLabel
                        control={<Checkbox checked={checked} onChange={handleCheckboxChange} name="checkedA" />}
                        label="동의"
                    />
                </div>

                <div style={{ marginTop: '32px' }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup aria-label="gender" name="gender" value={gender} onChange={handleGenderChange}>
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                            <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div style={{ marginTop: '32px' }}>
                    <TextField
                        select
                        style={{ width: '100%' }}
                        variant="outlined"
                        label={'선택: 일시불 / 정기결제'}
                        required={true}
                        onChange={event => {
                            // const option = event.target.value;
                            // setFieldValue('payOption', option);
                            // handleOptionChange(option, values.deliveryTerm);
                        }}
                        // onBlur={handleBlur('payOption')}
                        // error={!!(touched.payOption && errors.payOption)}
                        value={bank}
                    >
                        <MenuItem value={'kb'}>{'국민'}</MenuItem>
                        <MenuItem value={'sh'}>{'신한'}</MenuItem>
                    </TextField>
                </div>

                <div style={{ marginTop: '32px' }}>
                    <FormControlLabel
                        control={<Switch checked={agree} onChange={handleAgreeChange} name="agree" />}
                        label="Secondary"
                    />
                </div>

                <div style={{ marginTop: '32px' }}>
                    <DatePicker
                        label="시작일자"
                        // value={processedFrom}
                        onChange={(newValue) => {
                            // setProcessedFrom(newValue);
                        }}
                        renderInput={(params) => <TextField sx={{ width: { xs: '120px', md: 'auto' } }} size="small" {...params} onKeyDown={e => e.preventDefault()} />}
                    />
                </div>
            </form>
		</Box>
	);
}

export default InputPage;