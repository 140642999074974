import { useContext, useState } from 'react';
import API from 'api';
import Button from 'components/Button';
import { MemoryContext } from 'contexts/memory.context';
import CircularButton from 'components/CircularButton';


const ApiTestPage = () => {
    const { showAlert, logDB } = useContext(MemoryContext);

    const handleGetClick = () => {
        const params = { foo: 'FOO' };
        API.get('/test/get', { params })
        .then((data) => {
            console.log(data);
        })
    }

    const handlePostClick = () => {
        const data = { bar: 'BAR' };
        API.post('/test/post', data)
        .then((data) => {
            console.log(data);
        })
    }

    const handle404Click = () => {
        API.get('/test/non-exist')
        .then((data) => {
            console.log(data);
        })
    }

    const handle400Click = () => {
        API.get('/test/400')
        .then((data) => { console.log(data); });
    }

    const handle401Click = () => {
        API.get('/test/401')
        .then((data) => {
            console.log(data);
        })
    }

    const handle403Click = () => {
        API.get('/test/403')
        .then((data) => {
            console.log(data);
        })
    }

    const handle500Click = () => {
        API.get('/test/500')
        .then((data) => {
            console.log(data);
        })
    }

    const handleLogClick = () => {
        const data = {
            division: 'info',
            code: 'code01',
            message: 'hey',
            stack: null,
            meta: { foo: 'FOO', bar: 'BAR' },
        };
        logDB(data);
    }

    const [ file, setFile ] = useState();
    const [ fileUploadLoading, setFileUploadLoading ] = useState(false);
    const handleUploadFileClick = () => {
        if (!file) showAlert('파일을 선택해주세요.');

        if (file) {
            const data = new FormData();
            data.append('file', file);

            setFileUploadLoading(true);
            API.post('/test/upload-file', data)
            .then(fileInfo => {
                console.log(fileInfo);
            })
            .finally(() => { setFileUploadLoading(false); });
        }
    }

    return (
        <div>
            <Button sx={{ mb: 1 }} variant="contained" onClick={handleGetClick}>GET</Button><br />
            <Button sx={{ mb: 1 }} variant="contained" onClick={handlePostClick}>POST</Button><br />
            <Button sx={{ mb: 1 }} variant="contained" onClick={handle404Click}>404</Button><br />
            <Button sx={{ mb: 1 }} variant="contained" onClick={handle400Click}>400</Button><br />
            <Button sx={{ mb: 1 }} variant="contained" onClick={handle401Click}>401</Button><br />
            <Button sx={{ mb: 1 }} variant="contained" onClick={handle403Click}>403</Button><br />
            <Button sx={{ mb: 1 }} variant="contained" onClick={handle500Click}>500</Button><br />
            <Button sx={{ mb: 1 }} variant="contained" onClick={handleLogClick}>LOG</Button><br />

            <input type="file" accept="image/*" onChange={(e) => {
                if (e.target.files[0]) {
                    setFile(e.target.files[0]);
                }
            }} />
            <CircularButton sx={{ mb: 1 }} variant="contained" loading={fileUploadLoading} onClick={handleUploadFileClick}>UPLOAD</CircularButton>
        </div>
    );
}

export default ApiTestPage;