import React from 'react';
import Loading from './Loading';


const DataWrapper = ({ style, loading, fetched, children }) => {
    return (
        <div style={{ ...style }}>
            {loading ? (<Loading />) : <>
                {fetched && <>
                    {children}
                </>}
            </>}
        </div>
    );
}

export default DataWrapper;