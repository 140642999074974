import React, { useMemo, useState } from 'react';
import { Box, TextField } from '@mui/material';
import useSeaschUser from 'api/user/useSearchUser';
import Title from 'components/Title';
import CircularButton from 'components/CircularButton';
import UserTable from './UserTable';
import DataListWrapper from 'components/DataListWrapper';
import { useSearchParams } from 'react-router-dom';


const UserListPage = () => {
	const [ searchParams, setSearchParams ] = useSearchParams();

	const [ page, setPage ] = useState(0);
	const [ keyword, setKeyword ] = useState('');
	
	const params = useMemo(() => {
		const page = searchParams.get('page') || 0;
		const keyword = searchParams.get('keyword') || '';

		setPage(+page);
		setKeyword(keyword);

		return ({
			page,
			keyword,
		})
	}, [ searchParams ]);

	const { loading, error, rows, mayMore, totalCount, rowsPerPage, fetched } = useSeaschUser(page, params);

    const handleSearch = (event) => {
		event.preventDefault();

		setSearchParams({
			page: 0,
			keyword,
		});
    }

	return (
		<Box>
			<Title>사용자</Title>

			<form onSubmit={handleSearch}>
				<Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
					<TextField
						size='small'
						sx={{ minWidth: '180px' }}
						label={'키워드'}
						variant="outlined"
						onChange={(event) => { setKeyword(event.target.value); }}
						value={keyword}
					/>
					
					<CircularButton style={{ marginLeft: '8px' }} variant='contained' type="submit">검색</CircularButton>
				</Box>
			</form>
			

			<DataListWrapper
				loading={loading}
				fetched={fetched}
				length={rows?.length}
				nodata={'사용자가 없습니다.'}
			>
				<UserTable
					rows={rows}
					totalCount={totalCount}
					rowsPerPage={rowsPerPage}
					page={page}
					setPage={setPage}
				/>
			</DataListWrapper>
		</Box>
	);
}

export default UserListPage;