import colors from 'constants/colors';
import { styled } from '@mui/system';
import { TextField, Typography } from '@mui/material';


const Label = styled('div')(({theme}) => ({
    width: '80px',
    backgroundColor: '#f0f0f0',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
        width: '120px',
    },
    [theme.breakpoints.up('md')]: {
        width: '180px',
    },
    '& .MuiTypography-root': {
        color: colors.textSecondary,
    }
}));

const Content = styled('div')(({theme}) => ({
    padding: '8px 16px',
    flex: 1,
}));

const InputRow = styled('div')(({theme}) => ({
    display: 'flex',
    marginBottom: '4px',
}));

const InputContent = styled(TextField)(({theme}) => ({
    margin: '0 8px',
    flex: 1,
}));

const Row = ({ label, content }) => (<div style={{ display: 'flex', borderBottom: '1px solid #cccccc' }}>
    <Label>
        <Typography variant="body2">{label}</Typography>
    </Label>
    <Content>
        <Typography variant="body2">{content}</Typography>
    </Content>
</div>)

export {
    Label,
    Content,
    Row,
    InputRow,
    InputContent,
}