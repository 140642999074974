import colors from 'constants/colors';
import { actionCodes } from 'constants/globals';
import { format } from 'date-fns';


const datetime_pipe = (dateText) => {
    if (!dateText) return '-';
    const date = new Date(dateText);
    return format(date, 'yyyy-MM-dd HH:mm');
    // return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
}

const number_format_pipe = (x) => {
    if (!x) return x;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const mobile_pipe = (mobile) => {
    if (!mobile) return '-';
    if (mobile.length === 11) return `${mobile.slice(0, 3)}-${mobile.slice(3, 7)}-${mobile.slice(7, 11)}`;
    return mobile;
}

const user_state_pipe = (state) => {
    switch (state) {
        case 'pending': return '미인증';
        case 'normal': return '정상';
        case 'stopped': return '정지';
        case 'leaved': return '탈퇴';
        default: return '-';
    }
}

const item_props_pipe = (item) => {
    return item.props.map(prop => {
        const value = prop.values.val || prop.values.numVal || ''
        return `${prop.name}: ${value}`;
    }).join(', ');
}

const actioncode_pipe = (code) => {
    const result = actionCodes.find(el => el.code === code)?.name;
    return result || '-';
}

const actioncode_color_pipe = (code) => {
    switch (code) {
        case 'store': return colors.storeColor;
        case 'release': return colors.releaseColor;
        case 'adjustment': return colors.adjustmentColor;
        default: return colors.moveColor;
    }
}

const request_state_pipe = (state) => {
    switch (state) {
        case 'requested': return '요청중';
        case 'canceled': return '취소됨';
        case 'accepted': return '수락';
        case 'rejected': return '거절';
        case 'confirmed': return '완료';
        default: return '-';
    }
}

const bucket_name_pipe = (bucket) => {
    if (!bucket) return '-';
    return bucket.name || `입출고명세서_${bucket.id}`;
}

const filename_frompath_pipe = (filePath) => {
    if (!filePath) return '';
    const start = filePath.lastIndexOf("/");
    if (start < 0) return '';
    return filePath.slice(start + 1);
}



export {
    datetime_pipe,
    number_format_pipe,
    mobile_pipe,
    request_state_pipe,
    filename_frompath_pipe,

    user_state_pipe,
    item_props_pipe,
    actioncode_pipe,
    actioncode_color_pipe,
    bucket_name_pipe,
}