import React, { useContext } from 'react';
import { Box, Typography, Paper, TextField, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import CircularButton from 'components/CircularButton';
import { MemoryContext } from 'contexts/memory.context';


const PasswordSigninPage = () => {
    const { syncAuth } = useContext(AuthContext);
	const { routeAfterLogin } = useContext(MemoryContext);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: yup.object({
            email: yup.string().email('이메일 형식에 맞지 않습니다.').required('이메일을 입력해주세요.'),
            password: yup.string().required('비밀번호를 입력해주세요.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            API.post('/auth/password-signin', values)
            .then((data) => {
				return syncAuth();
            })
			.then(routeAfterLogin)
            .finally(() => { setSubmitting(false); });
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, handleSubmit, touched, errors } = formik;

	return (
		<form onSubmit={handleSubmit}>
			<Box sx={{ height: '100vh', backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
				<Paper sx={{ padding: 4, width: '320px', display: 'flex', flexDirection: 'column' }}>
					<img alt="LOGO" style={{ alignSelf: 'center' }} />
					<Typography variant='h6' sx={{ alignSelf: 'center', mt: 1, mb: 4 }}>이메일 로그인</Typography>
					
					<TextField
						type="email"
						label={(touched.email && errors.email) || '이메일'}
						variant="outlined"
						size="small"
						error={!!(touched.email && errors.email)}
						onChange={handleChange('email')}
						onBlur={handleBlur('email')}
						value={values.email}
						autoFocus
					/>
					<TextField
						type="password"
						label={(touched.password && errors.password) || '비밀번호'}
						variant="outlined"
						size="small"
						error={!!(touched.password && errors.password)}
						sx={{ mt: 2 }}
						onChange={handleChange('password')}
						onBlur={handleBlur('password')}
						value={values.password}
					/>
					<MuiLink component={Link} variant={'body2'} to={'/auth/find-email'} underline={'none'} sx={{ mt: 3 }}>이메일을 잊어버리셨나요?</MuiLink>
					<MuiLink component={Link} variant={'body2'} to={'/auth/find-password'} underline={'none'} sx={{ mt: 1 }}>비밀번호를 잊어버리셨나요?</MuiLink>
					
					<Box sx={{ mt: 5, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
						<MuiLink component={Link} underline="none" to={'/auth/password-signup'} sx={{ fontWeight: 'bold' }}>회원가입</MuiLink>
						<CircularButton type="submit" variant="contained" color="primary" loading={isSubmitting}>로그인</CircularButton>
					</Box>
				</Paper>
			</Box>
		</form>
	);
}


export default PasswordSigninPage;