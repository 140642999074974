import React, {  } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { datetime_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { alarm_code_pipe } from 'constants/alarm.constants';
import TablePaginationActions from 'components/TablePaginationActions';


export default function AlarmTable({ rows, totalCount, rowsPerPage, page, setPage }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table style={{ minWidth: '650px' }} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>일련번호</StyledTableCell>
                            <StyledTableCell>사용자</StyledTableCell>
                            <StyledTableCell>구분</StyledTableCell>
                            <StyledTableCell>메시지</StyledTableCell>
                            <StyledTableCell>읽음여부</StyledTableCell>
                            <StyledTableCell>생성일시</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th">{row.id}</StyledTableCell>
                                <StyledTableCell>{`${row.user.nickname} (${row.user.id})`}</StyledTableCell>
                                <StyledTableCell>{alarm_code_pipe(row.code)}</StyledTableCell>
                                <StyledTableCell>{row.message || '-'}</StyledTableCell>
                                <StyledTableCell>{row.read ? '읽음' : '알수없음'}</StyledTableCell>
                                <StyledTableCell>{datetime_pipe(row.createdAt)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    );
}