import { useEffect, useState } from "react";
import axios from "axios";
import API from "api";


export default function useSearchActionobj(page, { spaceId, placeId, userId, itemId, traderId, code, keyword, isProcessed, processedFrom, processedTo, trigger }) {
    const [ rows, setRows ] = useState();
    const [ count, setCount ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRows([]);
        setFetched(false);
        setMayMore(true);
        setError(null);
    }, [ spaceId, placeId, userId, itemId, traderId, code, keyword, isProcessed, processedFrom, processedTo, page, trigger ]);

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);

        const parameters = { 
            spaceId,
            placeId, 
            userId, 
            itemId, 
            traderId, 
            code, 
            keyword, 
            isProcessed,
            processedFrom,
            processedTo,
            page,
        };
        const body = { spaceId, parameters };
        API.post('/action/search', body, { cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setCount(data.count);
            setRowsPerPage(data.rowsPerPage);
            setRows(data.rows);
            setMayMore(data.rows.length > 0);
            setFetched(true);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ page, spaceId, placeId, userId, itemId, traderId, code, keyword, isProcessed, processedFrom, processedTo, trigger ]);

    return { loading, error, rows, mayMore, count, rowsPerPage, fetched };
}