import React, { createContext, useContext, useState } from 'react';
import API from 'api';
import { AppContext } from './app.context';


const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
	const { showSnackbar } = useContext(AppContext);

	const [ me, setMe ] = useState(null);

	const syncAuth = async () => {
		try {
			API.get('/auth/myinfo')
			.then(data => {
				setMe(data);
			});
		}
		catch(error) {
			console.error(error);
			setMe(null);
		}
	}

	const logout = async () => {
		return API.post('/auth/signout')
        .then(() => {
            showSnackbar('로그아웃했습니다.');
            syncAuth();
        });
	}

	return (
		<AuthContext.Provider
			value={{    
				me,
				syncAuth,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export {
	AuthContext,
	AuthContextProvider
};