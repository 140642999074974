import React from 'react';
import { Box } from '@mui/material';
import GoogleOAuthRequest from './GoogleOAuthRequest';
import VisitCount from './visit-count/VisitCount';



const StatisticsPage = () => {
	return (
		<Box>
			<VisitCount />

            <div style={{ marginTop: '24px' }}>
                <GoogleOAuthRequest />
            </div>
		</Box>
	);
}

export default StatisticsPage;