import React from 'react';
import { Box, Button, Typography, Divider } from '@mui/material';
import colors from 'constants/colors';
import { Label, Content, InputRow, InputContent, Row } from 'components/KeyValueData';



const KeyValuePage = () => {
    return (
        <Box>
            <Box sx={{ padding: '16px 0', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>구매자정보</Typography>
                <div style={{ borderTop: '3px solid #cccccc' }}>
                    {Row({ label: '이름', content: '양지훈' })}
                    {Row({ label: '이메일', content: 'yangji24@nate.com' })}
                    
                    <Box sx={{ display: 'flex', borderBottom: '1px solid #cccccc' }}>
                        <Label>
                            <Typography variant="body2">신발사이즈</Typography>
                        </Label>
                        <Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="body2" style={{ color: colors.textSecondary }}>[미지정]</Typography>
                            <Button color="primary" size="small" variant="outlined">설정하기</Button>
                        </Content>
                    </Box>
                </div>
            </Box>

            <Divider />
                        
            <Box sx={{ padding: '16px 0', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>받는사람정보</Typography>
                <InputRow>
                    <Label>
                        <Typography variant="body2">이름</Typography>
                    </Label>
                    <InputContent
                        variant="outlined"
                        required={true}
                        size="small"
                        // onChange={handleChange('nickname')}
                        // onBlur={handleBlur('nickname')}
                        // helperText={touched.nickname && errors.nickname || ' '}
                        // error={!!(touched.nickname && errors.nickname)}
                        // value={values.nickname}
                    />
                </InputRow>
                <InputRow>
                    <Label>
                        <Typography variant="body2">주소</Typography>
                    </Label>
                    <InputContent
                        variant="outlined"
                        placeholder="검색버튼을 누르세요."
                        required={true}
                        size="small"
                        disabled={true}
                        // onChange={handleChange('nickname')}
                        // onBlur={handleBlur('nickname')}
                        // helperText={touched.nickname && errors.nickname || ' '}
                        // error={!!(touched.nickname && errors.nickname)}
                        // value={values.nickname}
                    />
                    <Button variant="outlined" size="small" color="primary">검색</Button>
                </InputRow>
                <InputRow>
                    <Label>
                        <Typography variant="body2">상세주소</Typography>
                    </Label>
                    <InputContent
                        variant="outlined"
                        required={true}
                        size="small"
                        // onChange={handleChange('nickname')}
                        // onBlur={handleBlur('nickname')}
                        // helperText={touched.nickname && errors.nickname || ' '}
                        // error={!!(touched.nickname && errors.nickname)}
                        // value={values.nickname}
                    />
                </InputRow>
                <InputRow>
                    <Label>
                        <Typography variant="body2">연락처</Typography>
                    </Label>
                    <InputContent
                        variant="outlined"
                        required={true}
                        size="small"
                        // onChange={handleChange('nickname')}
                        // onBlur={handleBlur('nickname')}
                        // helperText={touched.nickname && errors.nickname || ' '}
                        // error={!!(touched.nickname && errors.nickname)}
                        // value={values.nickname}
                    />
                </InputRow>
                <InputRow>
                    <Label>
                        <Typography variant="body2">배송 요청사항</Typography>
                    </Label>
                    <InputContent
                        multiline
                        variant="outlined"
                        placeholder="요청사항을 입력하세요."
                        required={true}
                        size="small"
                        rows={4}
                        // onChange={handleChange('content')}
                        // onBlur={handleBlur('content')}
                        // helperText={touched.content && errors.content || ' '}
                        // error={!!(touched.content && errors.content)}
                        // value={values.content}
                    />
                </InputRow>
            </Box>
        </Box>
    );
}

export default KeyValuePage;