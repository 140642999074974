import React from 'react';
import { Box, Divider, Typography } from '@mui/material';


const Title = ({ children, style }) => {
	return (
        <Box style={{...style}}>
            <Typography variant="h6">{children}</Typography>
            <Divider />
        </Box>
	);
}

export default Title;