import React, { useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { datetime_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import TablePaginationActions from 'components/TablePaginationActions';


export default function LogTable({ rows, totalCount, rowsPerPage, page, setPage, checked, setChecked }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // detail
    const [ detailModalOpen, setDetailModalOpen ] = useState(false);
    const [ detail, setDetail ] = useState();
    const handleShowDetailClick = (row) => () => {
        setDetail(row);
        setDetailModalOpen(true);
    }
    // end: detail

    // checkbox interface
	const [ allChecked, setAllChecked ] = useState(false);
	const allCheckedPress = () => {
		setAllChecked(val => !val);

		const result = [];
		if (!allChecked) {
			for (let row of rows) {
				result.push(row.id);
			}
		}
		setChecked(result);
	}

	const handleCheck = (id) => () => {
		setChecked((list) => {
			const result = [...list];
			const index = list.indexOf(id);
			if (index > -1) {
				result.splice(index, 1);
			}
			else {
				result.push(id);
			}
			return result;
		});
	}
	// end

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table style={{ minWidth: '650px' }} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell><Checkbox checked={allChecked} onChange={allCheckedPress} style={{ backgroundColor: 'white'}} /></StyledTableCell>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell>관리</StyledTableCell>
                            <StyledTableCell>구분</StyledTableCell>
                            <StyledTableCell>코드</StyledTableCell>
                            <StyledTableCell>사용자</StyledTableCell>
                            <StyledTableCell>메세지</StyledTableCell>
                            <StyledTableCell>요청URL</StyledTableCell>
                            <StyledTableCell>정보</StyledTableCell>
                            <StyledTableCell>생성일시</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell><Checkbox checked={checked.indexOf(row.id) > -1} onChange={handleCheck(row.id)} /></StyledTableCell>
                                <StyledTableCell component="th">{row.id}</StyledTableCell>
                                <StyledTableCell>
                                    <Button variant='contained' size="small" onClick={handleShowDetailClick(row)}>보기</Button>
                                </StyledTableCell>
                                <StyledTableCell>{row.division === 'error' ? '에러' : '정보'}</StyledTableCell>
                                <StyledTableCell>{row.code}</StyledTableCell>
                                <StyledTableCell>{row.userId}</StyledTableCell>
                                <StyledTableCell>{row.message}</StyledTableCell>
                                <StyledTableCell>{row.originalUrl}</StyledTableCell>
                                <StyledTableCell style={{ maxWidth: '360px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{JSON.stringify(row.meta)}</StyledTableCell>
                                <StyledTableCell>{datetime_pipe(row.createdAt)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />

            {detail && <Dialog
				fullScreen
                open={detailModalOpen}
                onClose={() => { setDetailModalOpen(false); }}
            >
                <DialogContent>
                    <Typography variant='h5'>스택</Typography>
					<Typography variant='body1'>{detail.stack}</Typography>
                    <Typography variant='h5' sx={{ mt: 5 }}>정보</Typography>
                    <Typography variant='body1'><pre>{JSON.stringify(detail.meta, null, 2)}</pre></Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDetailModalOpen(false); }} color="primary" autoFocus>확인</Button>
                </DialogActions>
            </Dialog>}
        </Box>
    );
}