import React, { useState, useEffect, useContext } from 'react';
import { Typography, Checkbox, Box, Pagination } from '@mui/material';
import Loading from 'components/Loading';
import { AuthContext } from 'contexts/auth.context';
import API from 'api';
import Ticket from './Ticket';
import Guide from 'components/Guide';
import Title from 'components/Title';


export default function TicketPage() {
    const { me } = useContext(AuthContext);

    // data
    const [ data, setData ] = useState();

    // parameters
    const [ page, setPage ] = useState(0);
    const [ state, setState ] = useState('opened');

    // interface parameters
    const [ loading, setLoading ] = useState(false);
    const [ trigger, setTrigger ] = useState();
 
    useEffect(() => {
        if (me) {
           initLoad(); 
        }
    }, [ me, state ]);

    // 파라미터 (state) 가 바뀌면 초기화
    const initLoad = () => {
        setData(null);
        setPage(0);
        setTrigger(new Date().getTime());
    }

    useEffect(() => {
        if (trigger) {
            setTrigger(null);
            setLoading(true);

            const params = { page };
            if (state) params['state'] = state;
            API.get('/ticket/tickets', { params })
            .then(setData)
            .finally(() => {
                setLoading(false);
            });
        }
    }, [ trigger ]);

    const [ categories, setCategories ] = useState();
    useEffect(() => {
        API.get('/common/inquiry-categories')
        .then(setCategories);
    }, []);

    if (!categories) return null;

	return (
        <Box sx={{ p: 2 }}>
            <Title>1:1문의</Title>

            <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center', flexFlow: 'row-reverse' }}>
                <Checkbox
                    checked={state === 'opened'}
                    onChange={event => { 
                        if (event.target.checked) {
                            setState('opened');
                        }
                        else {
                            setState(null);
                        }
                    }}
                />
                <Typography variant="body1">진행중인 문의만 보기</Typography>
            </div>

            {!loading ? <>
                {data && <>
                    {data.rows.length > 0 ? <>
                        {data.rows.map(item => <Ticket key={item.id} ticket={item} loadData={initLoad} categories={categories} />)}
                    </> : <Guide>데이터가 없습니다.</Guide>}
                </>}
            </> : <>
                <Loading />
            </>}
            
            {data && <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}>
                <Pagination count={Math.ceil(data.totalCount / data.rowsPerPage)} color="primary" page={data.page + 1} onChange={(event, page) => {
                    setPage(page - 1);
                    setTrigger(new Date().getTime());
                }} />
            </div>}
        </Box>
	);
}