import { useEffect, useState } from "react";
import axios from "axios";
import API from 'api';


export default function useSearchItem(page, { keyword, spaceId }) {
    const [ rows, setRows ] = useState([]);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ fetched, setFetched ] = useState(false);
 
    useEffect(() => {
        setRows([]);
        setFetched(false);
        setMayMore(true);
        setError(null);
    }, [ keyword, spaceId, page ]);

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);
        const params = { 
            keyword, 
            spaceId,
            page,
        };
        API.get('/item/search', { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setTotalCount(data.totalCount);
            setRowsPerPage(data.rowsPerPage);
            setRows(data.rows);
            setMayMore(data.rows.length > 0);
            setFetched(true);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ keyword, spaceId, page ]);

    return { loading, error, rows, mayMore, totalCount, rowsPerPage, fetched };
}