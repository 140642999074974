import React, { createContext, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, useMediaQuery } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import colors from 'constants/colors';
import Space from 'views/space/detail/Space';


const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
    const isHandset = useMediaQuery(theme => theme.breakpoints.down('xs'));

    // snackbar 
	const [ snackbarOpen, setSnackbarOpend ] = useState(false);
    const [ snackbarMessage, setSnackbarMessage ] = useState();
	
    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpend(true);
    };
    
    const cloaseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpend(false);
    };
    // end: snackbar

    // alert
    const [ alertOpen, setAlertOpen ] = useState(false);
    const [ alertContent, setAlertContent ] = useState();

    const showAlert = (message) => {
        setAlertContent(message);
        setAlertOpen(true);
    };
    
    // dialog
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogData, setDialogData ] = useState(false);
    const showDialog = (data) => {
        setDialogData(data);
        setDialogOpen(true);
    }

    // spaceModal
    const [ spaceModalOpen, setSpaceModalOpen ] = useState(false);
    const [ spaceId, setSpaceId ] = useState();
    const showSpaceDetail = (spaceId) => { 
        setSpaceId(spaceId);
        setSpaceModalOpen(true);
    }

	return (
		<AppContext.Provider
			value={{
				showSnackbar,

                showAlert,

                showDialog,

                showSpaceDetail,
			}}
		>
			{children}

            {/* snackbar */}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: isHandset ? 'center' : 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={cloaseSnackbar}
                message={snackbarMessage}
            />

            {/* alert */}
            <Dialog
                open={alertOpen}
                onClose={() => { setAlertOpen(false); }}
            >
                <DialogContent style={{ minWidth: '240px' }}>
                    <Box sx={{ textAlign: 'center' }}><ErrorIcon fontSize='large' sx={{ my: 2, color: colors.warn }} /></Box>
                    <DialogContentText>{alertContent}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setAlertOpen(false) }} color="primary">확인</Button>
                </DialogActions>
            </Dialog>

            {/* dialog */}
            {dialogData && <Dialog
                open={dialogOpen}
                onClose={() => { setDialogOpen(false); }}
            >
                {dialogData.title && <DialogTitle>{dialogData.title}</DialogTitle>}
                <DialogContent style={{ minWidth: '240px' }}>
                    {dialogData.message && <DialogContentText style={{ whiteSpace: 'pre-line' }}>{dialogData.message}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    {dialogData.second && <Button onClick={() => {
                        setDialogOpen(false);
                        if (dialogData.second?.onClick) dialogData.second.onClick();
                    }} style={{ color: colors.textSecondary }}>{dialogData.second.text || '취소'}</Button>}

                    <Button onClick={() => {
                        setDialogOpen(false);
                        dialogData.first?.onClick();
                    }} color="primary" autoFocus>{dialogData.first?.text || '확인'}</Button>
                </DialogActions>
            </Dialog>}

            {/* modal: space detail */}
            <Dialog fullScreen open={spaceModalOpen} onClose={() => { setSpaceModalOpen(false); }}>
                {/* <DialogTitle>스페이스: abc</DialogTitle> */}

                <DialogContent>
                    <Space spaceId={spaceId} />
                </DialogContent>
                
                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setSpaceModalOpen(false); }}>닫기</Button>
                </DialogActions>
            </Dialog>

		</AppContext.Provider>
	);
};

export {
	AppContext,
	AppContextProvider
};