import React, { createContext, useContext, useState } from 'react';
import { AppBar, Box, Breadcrumbs, CssBaseline, Drawer, Toolbar, Typography, Link as MuiLink } from '@mui/material';
import SidebarMenus from './SidebarMenus';
import { Link, Outlet } from 'react-router-dom';
import colors from 'constants/colors';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import routes from 'Routes';
import { AuthContext } from 'contexts/auth.context';
import Guide from 'components/Guide';


const LayoutContext = createContext({});

const drawerWidth = 240;

function MainLayout() {
    const { me } = useContext(AuthContext);

    const [ pageTitle, setPageTitle ] = useState('');

    const breadcrumbs = useReactRouterBreadcrumbs(routes, { disableDefaults: true });

	return (
        <LayoutContext.Provider
			value={{    
				setPageTitle,
			}}
		>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Typography variant="h6" noWrap component="div">{pageTitle}</Typography>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: colors.gray50 },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto', }}>

                        <SidebarMenus setPageTitle={setPageTitle} />
                    </Box>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar />

                    <Breadcrumbs>
                        {breadcrumbs.map(({ match, breadcrumb }) => (
                            <MuiLink key={match.pathname} underline="hover" color="inherit" variant='body2' href={match.pathname}>{breadcrumb}</MuiLink>
                        ))}
                    </Breadcrumbs>

                    {me && !!me.auths.find(auth => auth.code === 'app_admin') ? <Box sx={{ mt: 3 }}>
                        <Outlet />
                    </Box> : <Box>
                        <Guide>로그인 해주세요.</Guide>
                        <Link to="/auth/password-signin">로그인 페이지로 이동</Link>
                    </Box>}
                </Box>
            </Box>
        </LayoutContext.Provider>
	);
}

export {
    MainLayout,
    LayoutContext,
}