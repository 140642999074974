import React, { useState, useEffect, Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import API from 'api';
import colors from 'constants/colors';
import Loading from 'components/Loading';
import Title from 'components/Title';
import { Content, Label, Row } from 'components/KeyValueData';
import { datetime_pipe, actioncode_pipe, number_format_pipe } from 'pipes';
import ActionsGroupbyWeekChart from './ActionsGroupbyWeekChart';



const User = ({ user, spaceId }) => {
	const haveOwnerAuth = !!user.auths.find(auth => auth.code === 'space.owner' && auth.refId === spaceId);

	return (
		<Box sx={{ display: 'flex', borderBottom: '1px solid #cccccc' }}>
			<Label>
				<Typography variant="body2">{user.nickname}({user.id})</Typography>
			</Label>
			<Content style={{ display: 'flex', alignItems: 'baseline' }}>
				<Typography variant="body2">{user.email}</Typography>
				{haveOwnerAuth && <Typography variant="body2" style={{ marginLeft: '16px', color: colors.info }}>소유자</Typography>}
			</Content>
		</Box>
	);
}

const Space = ({ spaceId }) => {
	const [ space, setSpace ] = useState();
	useEffect(() => {
		API.get(`/space/${spaceId}`)
		.then(setSpace);
	}, [ spaceId ]);

	const [ additionals, setAdditionals ] = useState();
	useEffect(() => {
		API.get(`/admin/space-additionals/${spaceId}`)
		.then(setAdditionals);
	}, [ spaceId ]);

	if (!space || !additionals) return (<Loading / >);

	return (
		<Box>
			<Title style={{ marginBottom: '40px' }}>스페이스 상세</Title>

			<Box sx={{ display: 'flex' }}>
				<Box sx={{ flex: 1 }}>
					{/* 기본정보 */}
					<Typography variant="subtitle2" style={{ marginBottom: '8px' }}>기본정보</Typography>
					<div style={{ borderTop: '3px solid #cccccc' }}>
						{Row({ label: '일련번호', content: space.id })}
						{Row({ label: '이름', content: space.name })}
						{Row({ label: '코드', content: space.uuid })}
					</div>

					{/* 사용자 */}
					<Box sx={{ mt: 5 }}>
						<Typography variant="subtitle2" style={{ marginBottom: '8px' }}>멤버</Typography>
						<div style={{ borderTop: '3px solid #cccccc' }}>
							{space.users.map(user => <User key={user.id} user={user} spaceId={spaceId} />)}
						</div>
					</Box>
					
					{/* 거래처 */}
					<Box sx={{ mt: 5 }}>
						<Typography variant="subtitle2" style={{ marginBottom: '8px' }}>거래처</Typography>
						<div style={{ borderTop: '3px solid #cccccc' }}>
							{space.traders.map(trader => <div key={trader.id}>{Row({ label: trader.name, content: `생성일시: ${datetime_pipe(trader.createdAt)}` })}</div>)}
							{space.traders.length === 0 && <Typography variant='body2' sx={{ mt: 2, color: colors.textSecondary }}>거래처가 없습니다.</Typography>}
						</div>
					</Box>

					{/* 플레이스 */}
					<Box sx={{ mt: 5 }}>
						<Typography variant="subtitle2" style={{ marginBottom: '8px' }}>플레이스</Typography>
						<div style={{ borderTop: '3px solid #cccccc' }}>
							{space.places.map(place => <div key={place.id}>{Row({ label: place.name, content: `생성일시: ${datetime_pipe(place.createdAt)}` })}</div>)}
							{space.places.length === 0 && <Typography variant='body2' sx={{ mt: 2, color: colors.textSecondary }}>하우스가 없습니다.</Typography>}
						</div>
					</Box>

					{/* 카테고리 및 속성 */}
					<Box sx={{ mt: 5 }}>
						<Typography variant="subtitle2" style={{ marginBottom: '8px' }}>카테고리</Typography>
						<div style={{ borderTop: '3px solid #cccccc' }}>
							{space.categories.map(category => <div key={category.id}>{Row({ label: category.name, content: category.props.map(prop => prop.name).join(', ') || '-' })}</div>)}
							{space.categories.length === 0 && <Typography variant='body2' sx={{ mt: 2, color: colors.textSecondary }}>카테고리가 없습니다.</Typography>}
						</div>
					</Box>
				</Box>
				
				<Box sx={{ flex: 1, ml: 4 }}>
					{/* 추가정보 */}
					<Box>
						<Typography variant="subtitle2" style={{ marginBottom: '8px' }}>추가정보</Typography>
						<div style={{ borderTop: '3px solid #cccccc' }}>
							{Row({ label: '총 아이템 개수', content: number_format_pipe(additionals.itemCount) })}
							{additionals.actionInfo.map(el => <Fragment key={el.code}>{Row({ label: '총 ' + actioncode_pipe(el.code) + ' 개수', content: number_format_pipe(el.count) })}</Fragment>)}
						</div>
					</Box>
				</Box>
			</Box>

			{/* 주간별 액션개수 */}
			<Box sx={{ mt: 5 }}>
				<ActionsGroupbyWeekChart
					rows={additionals.actionsGroupbyWeek}
				/>
			</Box>
			
		</Box>
	);
}

export default Space;