import React, { useContext } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Link } from '@mui/material';
import { datetime_pipe, user_state_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { AppContext } from 'contexts/app.context';
import AuthCode from 'constants/auth-codes.constants';
import TablePaginationActions from 'components/TablePaginationActions';


export default function UserTable({ rows, totalCount, rowsPerPage, page, setPage }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const { showSpaceDetail } = useContext(AppContext);

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table style={{ minWidth: '650px' }} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>일련번호</StyledTableCell>
                            <StyledTableCell>닉네임</StyledTableCell>
                            <StyledTableCell>이메일</StyledTableCell>
                            <StyledTableCell>상태</StyledTableCell>
                            <StyledTableCell>참여스페이스</StyledTableCell>
                            <StyledTableCell>소유스페이스</StyledTableCell>
                            <StyledTableCell>접속일시</StyledTableCell>
                            <StyledTableCell>가입일시</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th">{row.id}</StyledTableCell>
                                <StyledTableCell>{row.nickname}</StyledTableCell>
                                <StyledTableCell>{row.email}</StyledTableCell>
                                <StyledTableCell>{user_state_pipe(row.state)}</StyledTableCell>
                                <StyledTableCell>{row.spaces.map(space => <Link key={space.id} sx={{ mr: 1 }} href="#" onClick={() => { showSpaceDetail(space.id); }}>{`${space.name}(${space.id})`}</Link>)}</StyledTableCell>
                                <StyledTableCell>{row.auths.filter(auth => auth.code === AuthCode.spaceOwner).map(auth => <Link key={auth.refId} sx={{ mr: 1 }} href="#" onClick={() => { showSpaceDetail(auth.refId); }}>{auth.refId}</Link>)}</StyledTableCell>
                                <StyledTableCell>{datetime_pipe(row.accessedAt)}</StyledTableCell>
                                <StyledTableCell>{datetime_pipe(row.createdAt)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    );
}