const alarms = [
    { code: 'directly', email: true, inapp: true, name: '직접 메시지'},
    { code: 'send_space_invitation', email: true, inapp: true, name: '스페이스 초대장'},
    { code: 'reply_space_invitation', email: true, inapp: true, name: '스페이스 초대 답변'},
    { code: 'request_spacemember', email: true, inapp: true, name: '스페이스 멤버 요청'},
    { code: 'reply_spacemember', email: true, inapp: true, name: '스페이스 멤버 요청 답변'},
];

const AlarmCode = {
    directly: 'directly',                               // 관리자가 직접 보냄
        
    send_space_invitation: 'send_space_invitation',     // 오우너가 스페이스 멤버 초대장을 전달
    reply_space_invitation: 'send_space_invitation',

    request_spacemember: 'request_spacemember',         // 스페이스 멤버가 되게 해달라고 오우너에게 요청
    reply_spacemember: 'request_spacemember',
}

const alarmCodes = Object.values(AlarmCode);

const alarm_code_pipe = (code) => {
    const item = alarms.find(el => el.code === code);
    return item?.name || '-';
}

module.exports = {
    alarms,
    AlarmCode,
    alarmCodes,
    alarm_code_pipe
}