const AuthCode = {
    appAdmin: 'app_admin',

    spaceOwner: 'space.owner',
    schemeWritable: 'scheme.writable',

    actionStore: 'action.store',
    actionRelease: 'action.release',
    actionAdjustment: 'action.adjustment',
    actionMove: 'action.move',
    actionConfirmable: 'action.confirmable',
};






module.exports = AuthCode;