import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box } from '@mui/material';
import colors from 'constants/colors';
import Button from 'components/Button';
import CircularButton from 'components/CircularButton';
import useSearchSpace from 'api/space/useSearchSpace';
import DataListWrapper from 'components/DataListWrapper';
import SpaceTable from './list/SpaceTable';


const SpaceSelectionModal = ({ open, setOpen, handleSelectSpace }) => {
    const [ page, setPage ] = useState(0);
	const [ params, setParams ] = useState({});

    const [ keyword, setKeyword ] = useState('');
	
    const { loading, error, rows, mayMore, totalCount, rowsPerPage, fetched } = useSearchSpace(page, params);

    const handleSearch = (event) => {
        event.preventDefault();

        setParams({
			keyword,
        });
		setPage(0);
    }

    const onSelect = (row) => {
        handleSelectSpace(row);
        setOpen(false);
    }

	return (
		<Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={() => { setOpen(false); }}>
            <DialogTitle>스페이스 선택</DialogTitle>

            <DialogContent>
                <form onSubmit={handleSearch}>
                    <Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
                        <TextField
                            size='small'
                            sx={{ minWidth: '180px' }}
                            label={'키워드'}
                            variant="outlined"
                            onChange={event => { setKeyword(event.target.value) }}
                            value={keyword || ''}
                        />

                        <CircularButton type="submit" style={{ marginLeft: '8px' }} variant='contained' onClick={handleSearch}>검색</CircularButton>
                    </Box>
                </form>

                <DataListWrapper
                    loading={loading}
                    fetched={fetched}
                    length={rows?.length}
                    nodata={'스페이스가 없습니다.'}
                >
                    <SpaceTable
                        rows={rows}
                        totalCount={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        mode={'select'}
                        onSelect={onSelect}
                    />
                </DataListWrapper>
            </DialogContent>
            
            <DialogActions>
                <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setOpen(false); }}>닫기</Button>
            </DialogActions>
        </Dialog>
	);
}

export default SpaceSelectionModal;