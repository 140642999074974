import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box } from '@mui/material';
import colors from 'constants/colors';
import Button from 'components/Button';
import CircularButton from 'components/CircularButton';
import DataListWrapper from 'components/DataListWrapper';
import PlaceTable from './list/PlaceTable';
import useSearchPlace from 'api/place/useSearchPlace';


const PlaceSelectionModal = ({ open, setOpen, handleSelect, spaceId }) => {
    const [ page, setPage ] = useState(0);

    const [ params, setParams ] = useState({});
    const [ keyword, setKeyword ] = useState('');
    const [ trigger, setTrigger ] = useState();
	
    useEffect(() => {
        setParams({
			keyword,
            spaceId,
        });
		setPage(0);
    }, [ spaceId, trigger ]);

    const { loading, error, rows, mayMore, totalCount, rowsPerPage, fetched } = useSearchPlace(page, params);

    const handleSearch = (event) => {
        event.preventDefault();

        setTrigger(new Date().getTime());
    }

    const onSelect = (row) => {
        handleSelect(row);
        setOpen(false);
    }

	return (
		<Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={() => { setOpen(false); }}>
            <DialogTitle>하우스 선택</DialogTitle>

            <DialogContent>
                <form onSubmit={handleSearch}>
                    <Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
                        <TextField
                            size='small'
                            sx={{ minWidth: '180px' }}
                            label={'키워드'}
                            variant="outlined"
                            onChange={event => { setKeyword(event.target.value) }}
                            value={keyword || ''}
                        />

                        <CircularButton type="submit" style={{ marginLeft: '8px' }} variant='contained' onClick={handleSearch}>검색</CircularButton>
                    </Box>
                </form>

                <DataListWrapper
                    loading={loading}
                    fetched={fetched}
                    length={rows?.length}
                    nodata={'하우스가 없습니다.'}
                >
                    <PlaceTable
                        rows={rows}
                        totalCount={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        mode={'select'}
                        onSelect={onSelect}
                    />
                </DataListWrapper>
            </DialogContent>
            
            <DialogActions>
                <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setOpen(false); }}>닫기</Button>
            </DialogActions>
        </Dialog>
	);
}

export default PlaceSelectionModal;