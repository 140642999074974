module.exports = {
    // primaryDark: '#af6200',
    // primary: '#fb8c00',
    // primaryLight: '#fba333',
    // primaryTint: '#d0d4ed',
    // secondaryDark: '#f50057',
    // secondary: '#ab003c',
    // secondaryLight: '#f73378',
    primaryDark: '#1565c0',
    primary: '#1976d2',
    primaryLight: '#42a5f5',
    primaryTint: '#d0d4ed',
    secondaryDark: '#7b1fa2',
    secondary: '#9c27b0',
    secondaryLight: '#ba68c8',
    errorDark: '#ef5350',
    error: '#d32f2f',
    errorLight: '#c62828',
    warnLight: '#ff9800',
    warn: '#ed6c02',
    warnDark: '#e65100',
    infoLight: '#03a9f4',
    info: '#0288d1',
    infoDark: '#01579b',
    successLight: '#4caf50',
    success: '#2e7d32',
    successDark: '#1b5e20',
    textPrimary: 'rgba(0,0,0,0.87)',
    textSecondary: 'rgba(0,0,0,0.54)',
    textDisabled: 'rgba(0,0,0,0.38)',
    textHint: 'rgba(0,0,0,0.38)',
    textNeutral: 'rgb(45,55,72)',
    divider: 'rgba(0,0,0,0.12)',
    money: '#ae0000',
    gray50: '#FAFAFA',
    gray100: '#F5F5F5',
    gray200: '#EEEEEE',
    gray300: '#E0E0E0',
    gray400: '#BDBDBD',
    gray500: '#9E9E9E',
    gray600: '#757575',
    gray700: '#616161',
    gray800: '#424242',
    gray900: '#212121',
    grayBgColor: '#E7EBF0',

    background: '#f5f5f5',
    pageGray: '#f5f5f5',
    borderColor: '#eeeeee',
};