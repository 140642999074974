import { useEffect, useState } from "react";
import axios from "axios";
import API from 'api';


export default function useHostReferrerStatistics(page, { from, to }) {
    const [ rows, setRows ] = useState([]);
    const [ count, setCount ] = useState(0);
    const [ rowsInPage, setRowsInPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRows([]);
        setFetched(false);
        setMayMore(true);
        setError(null);
    }, [ from, to, page ]);

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);
        const params = { 
            from,
            to,
            page, 
        };
        API.get('/statistics/host-referrer-statistics', { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setCount(data.count);
            setRowsInPage(data.rowsInPage);
            setRows(data.rows);
            setMayMore(data.rows.length > 0);
            setFetched(true);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ from, to, page ]);

    return { loading, error, fetched, rows, mayMore, count, rowsInPage };
}