import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';
import colors from 'constants/colors';
import globals from 'constants/globals';
import Button from './Button';
import { Link, useNavigate } from 'react-router-dom';


const Page404 = () => {
    const navigate = useNavigate();


    return (
        <Box sx={{ height: '100vh', backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h3' style={{ marginBottom: '8px', color: colors.primary, fontWeight: 'bold' }}>{globals.appName}</Typography>
            <Card>
                <CardContent style={{ paddingTop: '32px', paddingBottom: '24px' }}>
                    <Box display='flex' alignItems='center' justifyContent='center'>
                        {/* <SearchIcon fontSize='large' color='primary' /> */}
                        <Typography variant='h4' style={{ marginLeft: '8px' }}>페이지를 찾을 수 없습니다.</Typography>
                    </Box>

                    <Typography variant='body2' style={{ margin: '24px 16px 0 16px' }}>{globals.appName}을 찾아주신 고객님,<br />입력하신 페이지의 주소가 잘못 입력되었거나, 변경 또는 삭제되어 요청하신 페이지를 찾을 수 없습니다.<br />입력하신 주소가 정확한지 다시 한번 확인해주시기 바랍니다.</Typography>

                    <Divider style={{ margin: '24px 0 16px 0' }} />
                    <Box>
                        <Button variant='contained' onClick={() => { navigate(-1) }}>이전 페이지로</Button>
                        <Button component={Link} to={'/statistics'} variant='contained' color='primary' style={{ marginLeft: '8px', color: 'white' }} onClick={() => { }}>홈으로</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default Page404;