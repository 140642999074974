import React, { useContext } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Link as MuiLink } from '@mui/material';
import { datetime_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { Link } from 'react-router-dom';
import { AppContext } from 'contexts/app.context';
import TablePaginationActions from 'components/TablePaginationActions';


export default function TraderTable({ rows, totalCount, rowsPerPage, page, setPage }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const { showSpaceDetail } = useContext(AppContext);

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table style={{ minWidth: '650px' }} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>일련번호</StyledTableCell>
                            <StyledTableCell>거래처</StyledTableCell>
                            <StyledTableCell>스페이스</StyledTableCell>
                            <StyledTableCell>생성일시</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th">{row.id}</StyledTableCell>
                                <StyledTableCell>{row.name}</StyledTableCell>
                                <StyledTableCell><MuiLink component={Link} to={'#'} onClick={() => { showSpaceDetail(row.spaceId); }}>{row.spaceName}</MuiLink></StyledTableCell>
                                <StyledTableCell>{datetime_pipe(row.createdAt)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    );
}