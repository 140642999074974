import React from 'react';
import { Box, Typography } from '@mui/material';
import colors from 'constants/colors';


export default function Guide({ children, style }) {
    return (
        <Box style={{ borderLeft: '6px solid #1565c0', padding: '0.7rem 1rem', backgroundColor: colors.primaryTint + '44', ...style }}>
            <Typography variant="body2" style={{ color: '#777777', whiteSpace: 'pre-line' }}>{children}</Typography>
        </Box>
    );
}